import {
  Flex,
  Button,
  Heading,
  Stack,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
  Spinner,
  Card,
  Avatar,
} from "@chakra-ui/react";
import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { getPatientById } from "../../../../api";
import { Prescriptions } from "./components/Prescriptions";

const PatientDetail = () => {
  const navigate = useNavigate();
  const { id = "" } = useParams<{ id: string }>();

  const {
    data: patient,
    isLoading,
    // isSuccess,
    // error,
  } = useQuery<Patient | null>("patient", () => {
    return getPatientById(id);
  });

  const goToNewPrescription = () => {
    navigate(`/clients/${id}/prescriptions/new`);
  };

  // const patientFullName = `${patient?.firstName} ${patient?.lastName}`;

  const renderPrescriptions = (prescriptions: Prescription[]) => {
    if (isLoading) {
      return (
        <Flex justifyContent="center">
          <Spinner />
        </Flex>
      );
    }
    return <Prescriptions prescriptions={prescriptions} />;
  };

  return (
    <Stack spacing={{ base: "8", lg: "6" }}>
      <Stack
        spacing="4"
        direction={{ base: "column", lg: "row" }}
        alignItems="flex-end"
        justify="space-between"
      >
        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbLink href="/">All Clients</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Text>{patient?.username}</Text>
          </BreadcrumbItem>
        </Breadcrumb>
      </Stack>
      <Flex pt={6} gap={8} alignItems="flex-start">
        <Stack flex={1} spacing="4">
          <Flex gap={4} pb={4}>
            <Heading size="lg" flex={1}>
              Prescriptions
            </Heading>
            <Button
              size="md"
              variant="solid"
              onClick={() => goToNewPrescription()}
            >
              New Prescription
            </Button>
          </Flex>
          {renderPrescriptions(patient?.prescriptions || [])}
        </Stack>
        <Card
          w="250px"
          bgColor="white"
          p={6}
          alignItems="center"
          justifyContent="center"
        >
          <Stack alignItems="center">
            <Avatar
              mb={3}
              boxSize={{ base: "18", lg: "24" }}
              name={patient?.username || "Anonymous"}
              bg={{ base: "gray.500", lg: "gray.600" }}
            />
            <Text fontSize="lg" fontWeight="bold">
              {patient?.username}
            </Text>
            <Text fontSize="sm" color="gray.500" pb={4}>
              {patient?.externalId}
            </Text>
            <Button
              size="md"
              variant="solid"
              onClick={() => navigate(`/clients/${id}/analytics`)}
            >
              Review Analytics
            </Button>
          </Stack>
        </Card>
      </Flex>
    </Stack>
  );
};

export default PatientDetail;
