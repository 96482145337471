import React, { useEffect } from "react";
import Routes from "./routes";
import "./App.css";
import { useAuth, ClerkLoading, ClerkLoaded } from "@clerk/clerk-react";
import { cacheToken } from "./lib/store";

function App() {
  const { getToken, signOut } = useAuth();

  useEffect(() => {
    const getClerkToken = async () => {
      const token = await getToken();
      cacheToken(token as string);
    };
    getClerkToken();
    const scheduler = setInterval(async () => getClerkToken(), 1000);

    return () => clearInterval(scheduler);
  }, []);

  return (
    <React.Fragment>
      {/* <ClerkLoading>
        <div>loading...</div>
      </ClerkLoading> */}
      {/* <ClerkLoaded> */}
      <Routes />
      {/* </ClerkLoaded> */}
    </React.Fragment>
  );
}

export default App;


