import { Divider, Flex, Avatar, Stack, Text } from "@chakra-ui/react";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { FiLogOut, FiUsers } from "react-icons/fi";
import { Logo } from "../../../components/Logo";
import { NavButton } from "./NavButton";
import { clearToken } from "../../../lib/store";
import { useAuth } from '@clerk/clerk-react';

interface SidebarProps {
  name?: string;
  user?: User;
}

export const Sidebar = ({ name, user }: SidebarProps) => {
  const navigate = useNavigate();
  const { signOut } = useAuth();

  const handleSignOut = async () => {
    await signOut();
    clearToken();
    navigate("/login");
  };

  return (
    <Flex as="section" minH="100vh" bg="bg-canvas">
      <Flex
        flex="1"
        bg="bg-accent"
        color="on-accent"
        maxW={{ base: "full", sm: "xs" }}
        py={{ base: "6", sm: "8" }}
        px={{ base: "4", sm: "6" }}
      >
        <Stack justify="space-between" spacing="1">
          <Stack spacing={{ base: "5", sm: "6" }} shouldWrapChildren>
            <Logo maxW={150} onClick={() => navigate("/")} cursor="pointer" />
            {/*
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <Icon as={FiSearch} color="on-accent" boxSize="5" />
              </InputLeftElement>
              <Input placeholder="Search" variant="filled" colorScheme="blue" />
            </InputGroup>
  */}
            <Stack spacing="1">
              <NavButton
                label="Clients"
                icon={FiUsers}
                onClick={() => navigate("/")}
              />
              { user && user.isRxAdmin ? <NavButton
                label="All Providers"
                icon={FiUsers}
                onClick={() => navigate("/users")}
              /> : null }
              { user && user.isRxAdmin ? <NavButton
                label="Organizations"
                icon={FiUsers}
                onClick={() => navigate("/organizations")}
              /> : null }
              { user && user.isOrgAdmin ? <NavButton
                label="My Organization"
                icon={FiUsers}
                onClick={() => navigate("/organization")}
              /> : null }
            </Stack>
          </Stack>
          <Stack spacing={{ base: "5", sm: "6" }}>
            <Stack spacing="1">
              {/* <NavButton label="Help" icon={FiHelpCircle} /> */}
              <NavButton
                label="Sign Out"
                icon={FiLogOut}
                onClick={handleSignOut}
              />
            </Stack>
            <Divider />
            <Text>Signed in as: {name}</Text>
          </Stack>
        </Stack>
      </Flex>
    </Flex>
  );
};
