import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  Text,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Checkbox,
  Select,
  Switch,
  Flex,
  HStack,
} from "@chakra-ui/react";
import * as React from "react";
import { useMutation, useQuery } from "react-query";
import { downloadCSV, getUser, orgEditUser } from "../../../../api";

interface EditUserPopupProps {
  onUserEdited?: (succeeded: boolean) => void;
  user: User;
}

export const EditUserPopup = ({ user, onUserEdited }: EditUserPopupProps) => {
  const isOpen = true;
  const { data } = useQuery<User, Error>("user", getUser);
  const [firstName, setFirstName] = React.useState(`${user.firstName}`);
  const [lastName, setLastName] = React.useState(`${user.lastName}`);
  const [orgPk, setOrgPk] = React.useState(`${user.orgPk}`);
  const [isOrgAdmin, setIsOrgAdmin] = React.useState(!!user.isOrgAdmin);
  const [error, setError] = React.useState<string | null>(null);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const onClose = (succeeded = false) => onUserEdited && onUserEdited(succeeded);

  const initialRef = React.useRef(null);

  const handleSave = async (id: string, firstName: string, lastName: string, orgPk: string) => {
    setLoading(true);

    try {
      let response = await orgEditUser({ id, firstName, lastName, isOrgAdmin });
      setLoading(false);
      onClose(true);
    } catch (error) {
      console.error("Error saving prescription: ", error);
      setError("Sorry, user could not be edited at this time.");
      setLoading(false);
      return;
    }
  };

  const renderError = () => {
    if (error) {
      return (
        <Text color="red" mt={4}>
          {error}
        </Text>
      );
    }
    return null;
  };

  const handleSaveClick = async () => {
    setError(null);

    handleSave(user.id, firstName, lastName, orgPk);
  };

  return (
    <>
      <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit User</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>First Name</FormLabel>
              <Input
                ref={initialRef}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Last Name</FormLabel>
              <Input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </FormControl>

            {(data && `user#${data.id}` == user.id) ? <></> : <FormControl mt={4}>
              <FormLabel>Is Admin for your Organization?</FormLabel>
              <Switch
                type="checkbox"
                isChecked={isOrgAdmin}
                onChange={(e) => {
                  setIsOrgAdmin(!isOrgAdmin)
                }}
              />
            </FormControl>}
            {renderError()}
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={handleSaveClick}
              isLoading={isLoading}
            >
              Save
            </Button>
            <Button onClick={() => onClose()}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
