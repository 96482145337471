import {
  Box,
  BoxProps,
  Heading,
  Select,
  Spinner,
  Stack,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import * as React from "react";
import { useQuery } from "react-query";
import { getOrganizations, getUser, getUsers } from "../../../api";
import { Users } from "./components/Users";
import { NewUserPopup } from "./components/NewUserPopup";
import { EditUserPopup } from "./components/EditUserPopup";

function UsersPage() {
  const {
    isLoading,
    data = [],
    isError,
    // error,
    refetch,
  } = useQuery<User[], Error>("users", getUsers);
  const {
    data: orgList = [],
  } = useQuery<Organization[], Error>("Organizations", getOrganizations);
  const { data: user } = useQuery<User, Error>("user", getUser);
  const [filteredUsers, setFilteredUsers] = React.useState<User[]>([]);
  const [orgFilter, setOrgFilter] = React.useState('');

  const size = useBreakpointValue({ base: "sm", lg: "md" });

  const filterByOrgPk = (orgPk: string) => {
    setOrgFilter(orgPk);
  };

  let onRefetch = async () => {
    await refetch();
  };

  return (isLoading ? (<Spinner />) : (user && user.isRxAdmin ? (
    <Stack spacing={{ base: "8", lg: "6" }}>
      <Stack
        spacing="4"
        direction={{ base: "column", lg: "row" }}
        alignItems="flex-end"
        justify="space-between"
      >
        <Heading
          size={size}
          fontWeight="medium"
        >
          All Providers
        </Heading>
        <Stack direction="row" spacing="3">
          <Select onChange={(e) => filterByOrgPk(e.target.value)}>
            <option value="">Any Organization</option>
            <option value="none">Without Organization</option>
            {orgList.map(org => (<option value={org.pk}>{org.name}</option>))}
          </Select>
          <NewUserPopup orgList={orgList} onUserAdded={() => refetch()} />
        </Stack>
      </Stack>
      <Card>
        <Users Users={orgFilter ? data.filter(user => orgFilter ? (orgFilter == 'none' ? !user.orgPk : user.orgPk == orgFilter) : true) : data} isLoading={isLoading} isError={isError} refetch={onRefetch} />
      </Card>
    </Stack>
  ) : (
    <Stack spacing={{ base: "8", lg: "6" }}>
      <div>Sorry, you are not allowed to access this page.</div>
    </Stack>
  )));
}

const Card = (props: BoxProps) => (
  <Box
    minH="36"
    bgColor={useColorModeValue("white", "gray.800")}
    boxShadow={useColorModeValue("sm", "sm-dark")}
    borderRadius="lg"
    {...props}
  />
);

export default UsersPage;

function setState(arg0: never[]): [any, any] {
  throw new Error("Function not implemented.");
}
