import * as React from "react";
import { useQuery } from "react-query";
import { Stack, Checkbox, Text, Select } from "@chakra-ui/react";
import { capitalize } from "lodash";
import { getGames } from "../../../../api";

type GameFiltersProps = {
  onFiltersChange?: ({ exercises, tags, positions }: Filters) => void;
};

const GameFilters = ({ onFiltersChange }: GameFiltersProps) => {
  const [selectedExercises, setSelectedExercises] = React.useState<string[]>(
    []
  );
  const [selectedTags, setSelectedTags] = React.useState<string[]>([]);
  const [selectedPositions, setSelectedPositions] = React.useState<string[]>(
    []
  );
  const { isLoading, isSuccess, data: games } = useQuery("games", getGames);

  const notifyFiltersChange = (updated: any) => {
    if (onFiltersChange) {
      onFiltersChange({
        exercises: selectedExercises,
        tags: selectedTags,
        positions: selectedPositions,
        ...updated,
      });
    }
  };

  const buildFilters = () => {
    let allExercises: string[] = [];
    let allFilters: string[] = [];
    let allTags: string[] = [];
    if (games) {
      games.forEach((game) => {
        const { exercises, filters, tags } = game;
        if (exercises) allExercises.push(...exercises);
        if (filters) allFilters.push(...filters);
        if (tags) allTags.push(...tags);
      });
    }
    const ret = {
      exercises: Array.from(new Set(allExercises.sort())),
      positions: Array.from(new Set(allFilters)),
      tags: Array.from(new Set(allTags)),
    };
    return ret;
  };

  const handleExerciseChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const filter = event.target.value;
    console.log({ filter });
    const newSelection = filter ? [filter] : [];
    setSelectedExercises(newSelection);
    notifyFiltersChange({ exercises: newSelection });
  };

  const handlePositionChange = (filter: string) => {
    const isSelected = selectedPositions.includes(filter);
    const newSelection = isSelected
      ? selectedPositions.filter((currentFilter) => currentFilter !== filter)
      : [...selectedPositions, filter];
    setSelectedPositions(newSelection);
    notifyFiltersChange({ positions: newSelection });
  };

  const handleTagChange = (filter: string) => {
    const isSelected = selectedTags.includes(filter);
    const newSelection = isSelected
      ? selectedTags.filter((currentFilter) => currentFilter !== filter)
      : [...selectedTags, filter];
    setSelectedTags(newSelection);
    notifyFiltersChange({ tags: newSelection });
  };

  const Tags = ({ tags }: { tags: string[] }) => {
    return (
      <Stack>
        {tags.map((tag) => (
          <Checkbox
            key={tag}
            name={tag}
            isChecked={selectedTags.includes(tag)}
            onChange={() => handleTagChange(tag)}
          >
            <Text>{formatLabel(tag)}</Text>
          </Checkbox>
        ))}
      </Stack>
    );
  };

  const Positions = ({ positions }: { positions: string[] }) => {
    return (
      <Stack pt={6}>
        {positions.map((position) => (
          <Checkbox
            key={position}
            name={position}
            isChecked={selectedPositions.includes(position)}
            onChange={() => handlePositionChange(position)}
          >
            <Text>{formatLabel(position)}</Text>
          </Checkbox>
        ))}
      </Stack>
    );
  };

  const Exercises = ({ exercises }: { exercises: string[] }) => {
    const value = selectedExercises.length ? selectedExercises[0] : "";
    return (
      <Stack pt={6}>
        <Select name="exercises" onChange={handleExerciseChange} value={value}>
          <option value="">Choose an exercise</option>
          {exercises.map((exercise) => (
            <option key={exercise} value={exercise}>
              {formatLabel(exercise)}
            </option>
          ))}
        </Select>
      </Stack>
    );
  };

  const formatLabel = (label: string) => {
    return capitalize(label.replace(/-/g, " "));
  };

  const { exercises, positions, tags } = buildFilters();

  const selectedFilters =
    selectedPositions.length + selectedTags.length + selectedExercises.length;

  return (
    <Stack spacing={2} bgColor="white" p={8}>
      <Text fontWeight="bold">Filter by:</Text>
      <Text color="brand.gray" fontSize="sm">
        {selectedFilters} filters selected
      </Text>
      <Stack pt={4} spacing={3}>
        <Tags tags={tags} />
        <Positions positions={positions} />
        <Exercises exercises={exercises} />
      </Stack>
    </Stack>
  );
};

export default GameFilters;
