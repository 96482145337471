import * as React from "react";
import { Stack, Text } from "@chakra-ui/layout";
import { Box, Flex } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { FiClock, FiX } from "react-icons/fi";
import { getGames } from "../../../../api";
import {
  formatDuration,
  formatTotalTime,
  generateId,
  groupGamesBySets,
} from "../../../../lib/util";

interface OverviewProps {
  games: GameConfig[];
  onRemoveGame?: (gameId: number, duration: number) => void;
}

const Overview = ({ games, onRemoveGame }: OverviewProps) => {
  const { isSuccess, data: allGames } = useQuery("games", getGames);

  interface GameItemProps {
    gameConfig: GameConfig;
  }

  const handleRemoveGame = (gameId: number, duration: number) => {
    if (onRemoveGame) onRemoveGame(gameId, duration);
  };

  const GameItem = ({ gameConfig }: GameItemProps) => {
    const { gameId, name, duration, sets = 1 } = gameConfig;
    let _name = name;
    if (allGames && allGames.length !== 0 && isSuccess) {
      const foundGame = allGames.find((game) => game.id === gameId);
      _name = foundGame?.name;
    }
    return (
      <Flex
        p={3}
        my={2}
        borderColor="gray.100"
        borderWidth={1}
        borderRadius={4}
        justifyContent="space-between"
      >
        <Box>
          <Text fontWeight="medium">{_name}</Text>
          <Flex alignItems="center" color="brand.gray">
            <FiClock />
            <Text pl={1} color="brand.gray" fontSize="sm">
              {sets}x{formatDuration(duration)}
            </Text>
            <Text></Text>
          </Flex>
        </Box>
        <FiX
          onClick={() => handleRemoveGame(+gameId, duration)}
          cursor="pointer"
        />
      </Flex>
    );
  };

  const renderSelectedPrescriptions = () => {
    if (!games || games.length === 0) {
      return (
        <Text color="gray.400" fontSize="sm">
          No games selected.
        </Text>
      );
    }

    return (
      <Box>
        {groupGamesBySets(games).map((gameConfig) => {
          return <GameItem key={generateId()} gameConfig={gameConfig} />;
        })}
      </Box>
    );
  };

  const totalTime = games.reduce((acc, curr) => {
    return acc + curr.duration;
  }, 0);

  return (
    <Stack spacing={4} bgColor="white" p={8} mb={10}>
      <Stack spacing={0}>
        <Text fontWeight="bold">Selected Games</Text>
        <Text fontSize="sm" color="gray.500">
          {formatTotalTime(totalTime)} total
        </Text>
      </Stack>
      {renderSelectedPrescriptions()}
    </Stack>
  );
};

export default Overview;
