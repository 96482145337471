import _ from "lodash";

// export const extractCheckedFilters = (groups: FilterGroups): FilterGroups => {
//   let filtered = JSON.parse(JSON.stringify(groups)) as FilterGroups; // Deep copy
//   for (const [groupId, filters] of Object.entries(filtered)) {
//     for (const [filterId, filter] of Object.entries(filters)) {
//       if (!filter.checked) {
//         // delete filtered[groupId][filterId];
//       }
//     }
//   }

//   return filtered;
// };

export const isMatch = (
  { exercises, tags, positions }: Filters,
  game: Game
) => {
  // If no filters are checked, return true
  if (
    (exercises === undefined || exercises.length === 0) &&
    (tags === undefined || tags.length === 0) &&
    (positions === undefined || positions.length === 0)
  ) {
    return true;
  }

  let tagsMatch = true;
  let positionsMatch = true;
  let exercisesMatch = true;

  // If tags are checked, game must match all of them
  if (tags !== undefined && tags.length > 0) {
    tagsMatch = _.intersection(game.tags, tags).length === tags.length;
  }

  // If positions are checked, game must match all of them
  if (positions !== undefined && positions.length > 0) {
    positionsMatch =
      _.intersection(game.filters, positions).length === positions.length;
  }

  // If exercises are checked, game must match all of them
  if (exercises !== undefined && exercises.length > 0) {
    exercisesMatch =
      _.intersection(game.exercises, exercises).length === exercises.length;
  }

  return tagsMatch && positionsMatch && exercisesMatch;
};
