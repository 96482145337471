import { SignUp } from "@clerk/clerk-react";
import { Container, Stack } from "@chakra-ui/react";
import { Footer } from "../../components/Footer";
import styled from "@emotion/styled";

function Register({ className }: { className?: string }) {
  return (
    <Container className={className} maxW="md" py={{ base: "12", md: "24" }}>
      <Stack spacing="8">
        <SignUp
          signInUrl="/login"
          appearance={{layout:{showOptionalFields:true}}}
        />
      </Stack>
      <Footer />
    </Container>
  );
}

export default styled(Register)`
  .cl-internal-b3fm6y {
    display: none;
  }
`;
