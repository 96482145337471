import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  Text,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Checkbox,
  Select,
  Switch,
  Flex,
  HStack,
} from "@chakra-ui/react";
import * as React from "react";
import { useMutation } from "react-query";
import { deleteUser, downloadCSV, editUser } from "../../../../api";

interface EditUserPopupProps {
  onUserEdited?: (succeeded: boolean) => void;
  user: User;
  orgList: Organization[];
}

export const EditUserPopup = ({ orgList, user, onUserEdited }: EditUserPopupProps) => {
  const isOpen = true;
  const [firstName, setFirstName] = React.useState(`${user.firstName}`);
  const [lastName, setLastName] = React.useState(`${user.lastName}`);
  const [orgPk, setOrgPk] = React.useState(`${user.orgPk}`);
  const [isOrgAdmin, setIsOrgAdmin] = React.useState(!!user.isOrgAdmin);
  const [error, setError] = React.useState<string | null>(null);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const onClose = (succeeded = false) => onUserEdited && onUserEdited(succeeded);

  const initialRef = React.useRef(null);

  const handleSave = async (id: string, firstName: string, lastName: string, orgPk: string) => {
    setLoading(true);

    try {
      let response = await editUser({ id, firstName, lastName, orgPk, isOrgAdmin });
      setLoading(false);
      onClose(true);
    } catch (error) {
      console.error("Error saving user: ", error);
      setError("Sorry, user could not be edited at this time.");
      setLoading(false);
      return;
    }
  };

  const renderError = () => {
    if (error) {
      return (
        <Text color="red" mt={4}>
          {error}
        </Text>
      );
    }
    return null;
  };

  const handleSaveClick = async () => {
    setError(null);

    handleSave(user.id, firstName, lastName, orgPk);
  };

  const handleDeleteUser = async () => {
    if (!window.confirm("Are you sure you want to delete this user? This is irreversible")) return;

    setLoading(true);
    try {
      let response = await deleteUser({ id: user.id });
      setLoading(false);
      onClose(true);
    } catch (error) {
      console.error("Error deleting user: ", error);
      setError("Sorry, user could not be deleted at this time.");
      setLoading(false);
      return;
    }
  };

  return (
    <>
      <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit User</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>First Name</FormLabel>
              <Input
                ref={initialRef}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Last Name</FormLabel>
              <Input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Organization</FormLabel>
              <Select
                value={orgPk}
                onChange={(e) => setOrgPk(e.target.value)}
              >
                <option value=""></option>
                {orgList.map(org => (
                  <option value={org.pk}>{org.name}</option>
                ))}
              </Select>
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Is Admin for their Organization?</FormLabel>
              <Switch
                type="checkbox"
                isChecked={isOrgAdmin}
                onChange={(e) => {
                  setIsOrgAdmin(!isOrgAdmin)
                }}
              />
            </FormControl>
            {renderError()}
          </ModalBody>

          <Flex justifyContent="space-between" paddingX="6" paddingY="4">
            <Button
              bg="brand.red"
              color="white"
              _hover={{ bg: 'brand.red', opacity: 0.8 }}
              onClick={handleDeleteUser}
              isLoading={isLoading}
            >
              Delete!
            </Button>
            <HStack spacing="1">
              <Button
                colorScheme="blue"
                mr={3}
                onClick={handleSaveClick}
                isLoading={isLoading}
              >
                Save
              </Button>
              <Button onClick={() => onClose()}>Cancel</Button>
            </HStack>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
};
