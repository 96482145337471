import { Box, Card, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { useQuery } from "react-query";
import { getTotalPlayTimeForRange } from "../../../../../api";
import { useParams } from "react-router-dom";

interface StatProps {
  hourOffset: number;
  label: string;
  extra?: string;
}

export const Stat = ({ hourOffset = 24, label, extra }: StatProps) => {
  const { id: patientId = "" } = useParams<{ id: string }>();
  const {
    data = { totalTime: 0 },
    isLoading,
    // isSuccess,
    // error,
  } = useQuery<{ totalTime: number } | null>(`totalPlay-${hourOffset}`, () => {
    return getTotalPlayTimeForRange(patientId, hourOffset);
  });

  const convertToHours = (milliseconds: number | undefined) => {
    try {
      if (!milliseconds) return 0;
      const hours = milliseconds / 1000 / 60 / 60;
      return hours.toFixed(2);
    } catch (e) {
      return 0;
    }
  };

  return (
    <Card
      w="full"
      bgColor="white"
      py={{ base: 6, md: 10 }}
      px={{ base: 2, md: 6 }}
      overflow="hidden"
    >
      <Stack>
        <Text
          fontSize={{ base: "2xl", md: "4xl", lg: "6xl" }}
          fontWeight="bold"
          lineHeight="100%"
          textAlign="center"
          color="brand.blue"
        >
          {convertToHours(data?.totalTime)} hr
        </Text>
        <Box>
          <Text fontSize="sm" textAlign="center" fontWeight="bold">
            {label}
          </Text>
          <Text fontSize="sm" textAlign="center">
            {extra}
          </Text>
        </Box>
      </Stack>
    </Card>
  );
};
