import * as React from "react";
import { Text } from "@chakra-ui/react";
import { Prescription } from "./Prescription";

interface PrescriptionsProps {
  prescriptions: Prescription[] | undefined;
}

export const Prescriptions = ({ prescriptions }: PrescriptionsProps) => {
  if (!prescriptions || prescriptions.length === 0) {
    return <Text>No prescriptions found.</Text>;
  }
  return (
    <>
      {prescriptions.map((prescription) => (
        <Prescription key={prescription.id} prescription={prescription} />
      ))}
    </>
  );
};
