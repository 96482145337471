import * as React from "react";
import {
    Flex,
    Heading,
    Stack,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Text,
    Grid,
    Input,
    Button,
} from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { useState } from "react";
import { migrateToBetaTables, updatePrescription } from "../../api";

const Migrate = () => {
    const navigate = useNavigate();
    const { id = "" } = useParams<{ id: string }>();

    const [externalId, setExternalId] = useState<string>("");
    const [providerEmail, setProviderEmail] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const handleSubmit = async () => {
        // console.log({ name, games, existingPrescription });
        setLoading(true);

        // Check if name is set, show error
        if (!externalId || externalId.length <= 0) {
            setError("Please provide the external ID");
            setLoading(false);
            return;
        }
        // Check if name is set, show error
        if (!providerEmail || providerEmail.length <= 0) {
            setError("Please provide the provider email");
            setLoading(false);
            return;
        }

        try {
            // Build prescription payload
            let response = null;
            response = await migrateToBetaTables(externalId, providerEmail);

            setLoading(false);
            if (response && response.id) {
                navigate(-1);
            }
        } catch (error) {
            console.error("Error saving prescription: ", error);
            setError("Could not save prescription. Please try again.");
            setLoading(false);
            return;
        }
    };

    return (
        <Stack spacing={{ base: "8", lg: "6" }}>
            <Stack flex={1} pt={6} gap={8}>
                <Flex gap={4} pb={4}>
                    <Heading size="lg" flex={1}>
                        Old-Prod Migration Console
                    </Heading>
                </Flex>
            </Stack>
            <Heading size="md">Patient External Id</Heading>
            <Input placeholder="Patient External Id"
                value={externalId}
                onChange={(e) => setExternalId(e.target.value)}
            />
            <Heading size="md">Provider Email Address</Heading>
            <Input placeholder="Provider Email Address" type="email"
                value={providerEmail}
                onChange={(e) => setProviderEmail(e.target.value)}
            />
            <Button onClick={handleSubmit}>Submit</Button>
        </Stack>
    );
};

export default Migrate;
