import {
  Flex,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  TableContainer,
  Spinner,
  Icon,
  Button,
} from "@chakra-ui/react";
import { FaChevronRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { EditOrganizationPopup } from "./EditOrganizationPopup";
import React from "react";
import { NewOrgUserPopup } from "./NewOrgUserPopup";

interface OrganizationsProps {
  Organizations: Organization[];
  isLoading?: boolean;
  isError?: boolean;
  refetch: Function;
}

export const Organizations = ({ Organizations, isLoading, isError, refetch }: OrganizationsProps) => {

  const [OrganizationUnderEdit, setOrganizationUnderEdit] = React.useState<Organization | null>(null);

  let tableContent;
  if (isError) {
    tableContent = (
      <Tr text-align="center" >
        <Td colSpan={3}
          paddingY="20px"
          textAlign="center"
          fontSize="sm"
          color="brand.red"
          fontWeight="bold"
        >Sorry, we are unable to load organizations due to an error.</Td>
      </Tr >
    );
  }
  else if (isLoading) {
    tableContent = (
      <Tr text-align="center" >
        <Td colSpan={3} paddingY="20px" textAlign="center"><Spinner /></Td>
      </Tr >
    );
  } else {
    tableContent = (
      Organizations.slice().sort((a, b) => `${a.name}` < `${b.name}` ? -1 : 1).map((organization: Organization) => (
        <OrganizationRow
          organization={organization}
          onClicked={(organization: Organization) => {
            console.log("SETTING ORG UNDER EDIT: ", organization);
            setOrganizationUnderEdit(organization);
          }}
          refetch={refetch}
        />
      ))
    );
  }

  return (
    <>
    {!!OrganizationUnderEdit && (<EditOrganizationPopup Organization={OrganizationUnderEdit} onOrganizationEdited={(succeeded: boolean) => { setOrganizationUnderEdit(null); succeeded && refetch(); }} />)}
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th># Providers</Th>
            <Th textAlign="right">Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {tableContent}
        </Tbody>
      </Table>
    </TableContainer>
    </>
  );
};

interface OrganizationProps {
  organization: Organization;
  onClicked: Function;
  refetch: Function;
}

const stringOrganizationType = (type: number) => {
  switch (type) {
    case 0: return 'Client';
    case 1: return 'Therapist';
    case 2: return 'Admin (Unused)';
    default: return 'Undefined';
  }
}

const OrganizationRow = ({
  organization,
  onClicked,
  refetch,
}: OrganizationProps) => {
  const navigate = useNavigate();
  const { pk, name } = organization;
  return (
    <Tr>
      <Td>{name}</Td>
      <Td>{(organization as any).users?.length || 0}</Td>
      <Td display="flex" gap="8px" justifyContent="flex-end">
        <NewOrgUserPopup organization={organization} onUserAdded={() => setTimeout(() => refetch(), 1000)} />
        <Button onClick={() => onClicked(organization)}>Edit Org</Button>
      </Td>
    </Tr>
  );
};
