import React from "react";
import { Stack, Text, Button, Select } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { getPresets } from "../../../../api";

interface PresetsProps {
  onApply?: (preset: Preset | undefined) => void;
}

const Presets = ({ onApply }: PresetsProps) => {
  const [selectedPreset, setSelectedPreset] = React.useState<string>("");

  const {
    data: presets = [],
    isLoading,
    // isSuccess,
    // error,
  } = useQuery<Preset[] | null>("presets", () => {
    return getPresets();
  });

  const handlePresetChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    console.log("Preset changed: ", e.target.value);
    setSelectedPreset(e.target.value);
  };

  const handleApply = () => {
    console.log("Applying preset...", selectedPreset);
    if (onApply && presets && selectedPreset.length > 0) {
      const selected = presets.find((p) => p.id === selectedPreset);
      onApply(selected);
    }
  };

  const renderPresets = () => {
    if (isLoading) {
      return <option value="">Loading...</option>;
    }
    if (!presets) {
      return <option value="">No presets found</option>;
    }
    const elem = presets.map((preset) => {
      return (
        <option key={preset.id} value={preset.id}>
          {preset.name}
        </option>
      );
    });
    return elem;
  };

  return (
    <Stack spacing={4} bgColor="white" p={8} mb={10}>
      <Text fontWeight="bold">Presets</Text>
      <Stack direction="row" spacing="3">
        <Select onChange={handlePresetChange}>
          <option value="">Select a preset</option>
          {renderPresets()}
        </Select>
        <Button
          variant="solid"
          onClick={handleApply}
          disabled={!presets || presets.length <= 0}
        >
          Apply
        </Button>
      </Stack>
    </Stack>
  );
};

export default Presets;
