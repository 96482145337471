import React, { useState } from "react";
import {
  Box,
  Card,
  Flex,
  Select,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import Chart from "react-apexcharts";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { types, config } from "./chart.options";
import { getMetricsForChartId } from "../../../../../../api";

export const PatientChart = () => {
  const { id: patientId = "" } = useParams<{ id: string }>();
  const [selectedChartId, setSelectedChartId] = useState<number>(2);

  const {
    data = [],
    isLoading,
    // isSuccess,
    // error,
  } = useQuery<MetricDataPoint[] | [] | null>(
    `metricsData-${selectedChartId}`,
    () => {
      return getMetricsForChartId(patientId, selectedChartId);
    }
  );

  //
  //  Derived state
  //

  const selectedChart = types.find((cid) => cid.id === selectedChartId);

  const options = {
    ...config,
    yaxis: {
      ...config.yaxis,
      title: {
        ...config.yaxis.title,
        text: selectedChart?.yLabel,
      },
    },
  };

  //
  //  Prepare data for chart
  //
  let metrics: any[] = [];
  let sum = 0;
  const shoulderAbductionChartIds = [ 6, 13 ];  // ids of gestures involving shoulder abduction. we filter out impossible values > 160 for these gestures.
  const shoulderAbductionLimit = 160;
  const isShoulderAbduction = shoulderAbductionChartIds.includes(selectedChartId);

  if (data) {
    for (let i = 0; i < data.length; i++) {
      const element = data[i];

      if (isShoulderAbduction && !!element.value && element.value > shoulderAbductionLimit) continue;

      metrics.push([i, element.value]);
      if (!!element.value) sum += element.value;
    }
  }

  // Filter metrics data to only use data points within 2 standard deviations
  const mean = sum / metrics.length;
  let variance = 0;
  metrics.forEach(metric => {
    variance += Math.pow(metric[1] - mean, 2);
  });
  variance /= metrics.length;
  const stdDev = Math.sqrt(variance);

  const lowerThreshold = mean - 2 * stdDev;
  const upperThreshold = mean + 2 * stdDev;

  console.log('Before standard dev filter: ', metrics, 'standard dev: ', stdDev);
  metrics = metrics.filter(metric => metric[1] > lowerThreshold && metric[1] < upperThreshold);
  console.log('After standard dev filter: ', metrics);

  const handleChartChange = (selectedId: number) => {
    setSelectedChartId(selectedId);
  };

  const ChartMenu = () => {
    const allCharts = types
      .sort((o1, o2) => {
        return o1.dropdownName.localeCompare(o2.dropdownName);
      })
      .map((option, index) => {
        return (
          <option key={option.id} value={option.id}>
            {option.dropdownName}
          </option>
        );
      });

    return (
      <Select
        borderWidth={0}
        fontWeight="bold"
        bgColor="white"
        color="brand.blue"
        value={selectedChartId}
        onChange={(e) => {
          handleChartChange(+e.target.value);
        }}
      >
        {allCharts}
      </Select>
    );
  };

  const DateMenu = () => {
    return null;
    return (
      <Select bgColor="white" borderWidth={0}>
        <option value="1">Last 24 hours</option>
        <option value="7">Last 7 days</option>
        <option value="30">Last 30 days</option>
        <option value="0">Select custom...</option>
      </Select>
    );
  };

  const MainChart = () => {
    if (isLoading) {
      return (
        <Flex justifyContent="center">
          <Spinner />
        </Flex>
      );
    }
    if (metrics.length === 0) {
      return (
        <Flex justifyContent="center">
          <Text>No data available</Text>
        </Flex>
      );
    }
    return (
      <Chart
        options={options}
        series={[
          {
            name: "Measurement",
            data: metrics,
          },
        ]}
        type="scatter"
        // responsive={responsiveOptions}
      />
    );
  };

  return (
    <Card w="full" bgColor="white" overflow="hidden">
      <Stack p={6} bgColor="gray.100">
        {/* <Text fontSize="sm">Select an exercise:</Text> */}
        <Flex flexDir={{ base: "column", md: "row" }} gap={{ base: 2, md: 6 }}>
          <ChartMenu />
          <DateMenu />
        </Flex>
      </Stack>
      <Box p={6}>
        <MainChart />
      </Box>
    </Card>
  );
};
