import * as React from "react";
import {
  Flex,
  Stack,
  HStack,
  Card,
  Image,
  Select,
  Button,
  Text,
} from "@chakra-ui/react";
import { FiCheck } from "react-icons/fi";
import { env } from "../../../../config/env";
import { Exercises } from "./Exercises";
import { Tags } from "./Tags";
import { Positions } from "./Positions";

interface GameItemProps {
  game: Game;
  selected?: boolean;
  onGameAdded?: (game: Game, totalSets: number, duration: number) => void;
}

const DURATIONS = [
  { value: 30, label: "30 seconds" },
  { value: 45, label: "45 seconds" },
  { value: 60, label: "60 seconds" },
  { value: 90, label: "90 seconds" },
  { value: 120, label: "2 minutes" },
  { value: 180, label: "3 minutes" },
  { value: 240, label: "4 minutes" },
  { value: 300, label: "5 minutes" },
  { value: 600, label: "10 minutes" },
] as { value: number; label: string }[];

const GameItem = ({ game, onGameAdded }: GameItemProps) => {
  const isProd = env.stage !== "dev" && env.stage !== "local";
  const { thumb1x, thumb2x, minTime = 0, maxTime = 10000 } = game;

  const [duration, setDuration] = React.useState(isProd ? minTime : 30);
  const [totalSets, setTotalSets] = React.useState(1);
  const [showCheck, setShowCheck] = React.useState(false);

  const handleClick = (game: Game) => {
    if (onGameAdded) {
      onGameAdded(game, totalSets, duration);
      setShowCheck(true);
    }
  };

  const handleSetChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTotalSets(parseInt(e.target.value));
    setShowCheck(false);
  };

  const handleDurationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setDuration(parseInt(e.target.value));
    setShowCheck(false);
  };

  const renderDurationOptions = (minTime: number, maxTime: number) => {
    let filteredDurations = DURATIONS;

    // Only filter for PROD
    if (isProd) {
      filteredDurations = DURATIONS.filter(
        (d) => d.value >= minTime && d.value <= maxTime
      );
    }
    return filteredDurations.map((d) => (
      <option key={d.value} value={d.value}>
        {d.label}
      </option>
    ));
  };

  const thumbnailUrl1x = thumb1x
    ? thumb1x
    : "https://via.placeholder.com/150x150?text=Game+Image";
  const thumbnailUrl2x = thumb2x
    ? thumb2x
    : "https://via.placeholder.com/150x150?text=Game+Image";

  return (
    <Card bgColor="white" borderRadius="lg">
      <Flex
        gap="10px"
        alignItems={{ base: "flex-start", lg: "center" }}
        flexDirection={{ base: "column", lg: "row" }}
      >
        <Image
          src={thumbnailUrl1x}
          srcSet={`${thumbnailUrl1x} 1x, ${thumbnailUrl2x} 2x`}
          flex={1}
          display={{ base: "none", lg: "block" }}
          h={{ base: "200px", lg: "100%" }}
          w={{ base: "100%", lg: "auto" }}
          bgColor="red"
          objectFit="cover"
          borderTopLeftRadius="lg"
          borderTopRightRadius={{ base: "lg", lg: "none" }}
          borderBottomLeftRadius={{ base: "none", lg: "lg" }}
        />
        <Stack flex={1} p={6} spacing={2}>
          <Text fontWeight="bold">{game.name}</Text>
          <Text fontSize="sm">{game.description}</Text>

          <Flex fontSize="xs" color="gray.500" gap={1} flexWrap="wrap">
            <Tags tags={game?.tags} />
            <Exercises exercises={game?.exercises} />
            <Positions positions={game?.filters} />
          </Flex>
          <Flex pt={4} gap={4}>
            <Select flex={1} onChange={handleSetChange} defaultValue={1}>
              <option value={1}>1x</option>
              <option value={2}>2x</option>
              <option value={3}>3x</option>
              <option value={4}>4x</option>
              <option value={5}>5x</option>
              <option value={6}>6x</option>
            </Select>
            <Select flex={2} value={duration} onChange={handleDurationChange}>
              {renderDurationOptions(minTime, maxTime)}
            </Select>
            <Button
              size="md"
              bgColor="brand.blue"
              onClick={() => handleClick(game)}
            >
              {showCheck && <FiCheck />} Add
            </Button>
          </Flex>
        </Stack>
      </Flex>
    </Card>
  );
};

export default GameItem;
