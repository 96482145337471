import type { FC, ReactNode } from "react";
import { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { loadToken } from "../lib/store";
import { SignInButton, SignedIn, SignedOut, UserButton } from "@clerk/clerk-react"

import { Logo } from "./Logo";

interface AuthGuardProps {
  children: ReactNode;
}

export const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  
  return <div>
    <SignedOut>
      <Navigate to="/login" replace />
    </SignedOut>
    {/* <Logo /> */}
    <SignedIn>
      {/* <Logo /> */}
      {children}
    </SignedIn>
  </div>
    ;
};
