import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { env } from "../config/env";
import { loadToken } from "../lib/store";
axios.defaults.withCredentials = true
export const endpoints = {
  login: () => "/v3/login",
  createPatient: () => "/v3/patients",
  getPatients: () => "/v3/patients",
  getPatientById: (id: string) => `/v3/patients/${id}`,
  createPrescription: (id: string) => `/v3/patients/${id}/prescriptions`,
  editPrescription: (patientId: string, prescriptionId: string) =>
    `/v3/patients/${patientId}/prescriptions/${prescriptionId}/edit`,
  updatePrescription: (patientId: string, prescriptionId: string) =>
    `/v3/patients/${patientId}/prescriptions/${prescriptionId}`,
  archivePrescription: (patientId: string, prescriptionId: string) =>
    `/v3/patients/${patientId}/prescriptions/${prescriptionId}`,
  getUser: () => "/v3/user/profile",
  getPresets: () => "/v3/presets",
  getGames: () => "/v3/games",
  getTotalPlayTimeForRange: (id: string) => `/v3/patients/${id}/analytics/time`,
  getMetricsByPatientId: (id: string) => `/v3/patients/${id}/analytics/metrics`,
  getCSV: () => "/v3/patients/csv/download",
  migrateToBetaTables: () => "/v3/transfer-beta",
  getUsers: () => "/v3/users",
  editUser: (id: string) => `/v3/user/edit/${id}`,
  createUser: () => `/v3/user/create`,
  getOrganizations: () => "/v3/orgs",
  editOrganization: (id: string) => `/v3/orgs/edit/${id}`,
  createOrganization: () => `/v3/orgs/create`,

  getOrganization: () => "/v3/organization",
  getOrgUsers: () => "/v3/org-users",
  createOrgUser: () => "/v3/org/user/create",
  editOrgUser: () => "/v3/org/user/edit/id",
  deleteUser: () => "/v3/user/delete",

  setNewPassword: () => "/v3/set-password",
};

const buildHeaders = () => {
  const token = loadToken();
  return {
    Authorization: `Bearer ${token}`,
  };
};

function getSessionFromCookie() {
  const sessionRegex = /__session=([^;]*)/;
  const matches = document.cookie.match(sessionRegex);
  if (matches && matches.length > 1) {
    return matches[1];
  }
  return null;
}

function getUATFromCookie() {
  const sessionRegex = /__client_uat=([^;]*)/;
  const matches = document.cookie.match(sessionRegex);
  if (matches && matches.length > 1) {
    return matches[1];
  }
  return null;
}
export const buildUrl = (endpoint: string) => {
  return `${env.baseUrl}${endpoint}?session=${getSessionFromCookie()}&uat=${getUATFromCookie()}`;
};

export const login = async (email: string, password: string) => {
  const response = await axios.post(buildUrl(endpoints.login()), {
    email,
    password,
  });
  return response.data;
};

interface AddPatientParams {
  externalId: string;
  username?: string;
}

export const addPatient = async ({
  externalId,
  username,
}: AddPatientParams) => {
  const response = await axios.post<User>(
    buildUrl(endpoints.createPatient()),
    {
      externalId,
      username,
    },
    {
      headers: buildHeaders(),
    }
  );
  return response.data;
};

export const getPatients = async () => {
  const response = await axios.get<Patient[]>(
    buildUrl(endpoints.getPatients()),
    {
      headers: buildHeaders(),
    }
  );
  return response.data;
};

export const getPatientById = async (id: string) => {
  if (!id) {
    return null;
  }
  const response = await axios.get<Patient>(
    buildUrl(endpoints.getPatientById(id)),
    {
      headers: buildHeaders(),
    }
  );
  return response.data;
};

export const addPrescription = async (
  prescription: Prescription,
  patientId: string
) => {
  const response = await axios.post(
    buildUrl(endpoints.createPrescription(patientId)),
    prescription,
    {
      headers: buildHeaders(),
    }
  );
  return response.data;
};

export const editPrescription = async (
  patientId: string,
  prescriptionId: string | undefined
) => {
  if (prescriptionId === undefined) {
    console.log("Prescription ID was not provided");
    return null;
  }
  const response = await axios.get(
    buildUrl(endpoints.editPrescription(patientId, prescriptionId)),
    {
      headers: buildHeaders(),
    }
  );
  return response.data;
};

export const updatePrescription = async (
  prescription: Prescription,
  patientId: string
) => {
  if (!prescription.id) {
    console.error("Prescription ID is required");
    return null;
  }
  const response = await axios.put(
    buildUrl(endpoints.updatePrescription(patientId, prescription.id)),
    prescription,
    {
      headers: buildHeaders(),
    }
  );
  return response.data;
};

export const archivePrescription = async (
  patientId: string,
  prescriptionId: string
) => {
  const response = await axios.delete(
    buildUrl(endpoints.archivePrescription(patientId, prescriptionId)),
    {
      headers: buildHeaders(),
    }
  );
  return response.data;
};

export const getTotalPlayTimeForRange = async (
  id: string,
  hourOffset: number
) => {
  if (!id) {
    return null;
  }

  // Generate offset start date eg. 2023-05-01T00:00:00.000Z
  const now = new Date().getTime();
  const startDate = new Date(now - hourOffset * 60 * 60 * 1000).toISOString();
  const response = await axios.post<{ totalTime: number }>(
    buildUrl(endpoints.getTotalPlayTimeForRange(id)),
    {
      startDate,
      endDate: "",
    },
    {
      headers: buildHeaders(),
    }
  );
  return response.data;
};

export const getMetricsForChartId = async (id: string, chartId: number) => {
  if (!id) {
    return null;
  }
  const response = await axios.post<MetricDataPoint>(
    buildUrl(endpoints.getMetricsByPatientId(id)),
    {
      chartId,
    },
    {
      headers: buildHeaders(),
    }
  );
  try {
    const { metrics } = response.data as any;
    return metrics;
  } catch (e) {
    return [];
  }
};

export const getMetricsForChartType = async (
  id: string,
  gestureType: string,
  metricId: string
) => {
  if (!id || !gestureType || !metricId) {
    return null;
  }
  const response = await axios.get<Metric[]>(
    buildUrl(endpoints.getTotalPlayTimeForRange(id)),
    {
      headers: buildHeaders(),
    }
  );
  return response.data;
};

export const getUser = async () => {
  const url = buildUrl(endpoints.getUser());
  const response = await axios.get<User>(url, {
    headers: buildHeaders(),
  });
  return response.data;
};

export const getPresets = async () => {
  const response = await axios.get<Preset[]>(buildUrl(endpoints.getPresets()), {
    headers: buildHeaders(),
  });
  return response.data;
};

export const getGames = async () => {
  const url = buildUrl(endpoints.getGames());
  const response = await axios.get<Game[]>(url, {});
  console.log("Game response: ", response);
  return response.data;
};

export const downloadCSV = async () => {
  const response = await axios.get(
    buildUrl(endpoints.getCSV()),
    {
      headers: buildHeaders(),
    }
  );
  return response.data;
};

export const migrateToBetaTables = async (
  externalId: string,
  providerEmail: string | undefined
) => {
  const response = await axios.post(
    buildUrl(endpoints.migrateToBetaTables()),
    {
      externalId,
      providerEmail
    },
    {
      headers: buildHeaders(),
    }
  );
  return response.data;
};


interface RxAddOrgUserParams {
  firstName?: string;
  lastName?: string;
  email: string;
  orgPk: string;
  isOrgAdmin: boolean;
}
export const rxAddOrgUser = async ({
  firstName,
  lastName,
  email,
  orgPk,
  isOrgAdmin,
}: RxAddOrgUserParams) => {
  const response = await axios.post<User>(
    buildUrl(endpoints.createUser()),
    {
      firstName,
      lastName,
      email,
      orgPk,
      isOrgAdmin,
    },
    {
      headers: buildHeaders(),
    }
  );
  return response.data;
};

interface EditUserParams {
  id: string;
  firstName: string;
  lastName: string;
  orgPk: string;
  isOrgAdmin: boolean;
}
export const editUser = async ({
  id,
  firstName,
  lastName,
  orgPk,
  isOrgAdmin,
}: EditUserParams) => {
  const response = await axios.post<User>(
    buildUrl(endpoints.editUser(encodeURIComponent(id))),
    {
      id,
      firstName,
      lastName,
      orgPk,
      isOrgAdmin,
    },
    {
      headers: buildHeaders(),
    }
  );
  return response.data;
};

export const getUsers = async () => {
  const response = await axios.get<User[]>(
    buildUrl(endpoints.getUsers()),
    {
      headers: buildHeaders(),
    }
  );
  return response.data;
};

export const getOrganizations = async () => {
  const response = await axios.get<Organization[]>(
    buildUrl(endpoints.getOrganizations()),
    {
      headers: buildHeaders(),
    }
  );
  return response.data;
};

interface AddOrganizationParams {
  name: string;
}
export const addOrganization = async ({
  name,
}: AddOrganizationParams) => {
  const response = await axios.post<Organization>(
    buildUrl(endpoints.createOrganization()),
    {
      name,
    },
    {
      headers: buildHeaders(),
    }
  );
  return response.data;
};

interface EditOrganizationParams {
  pk: string;
  name: string;
}
export const editOrganization = async ({
  pk,
  name
}: EditOrganizationParams) => {
  const response = await axios.post<Organization>(
    buildUrl(endpoints.editOrganization(encodeURIComponent(pk))),
    {
      pk,
      name
    },
    {
      headers: buildHeaders(),
    }
  );
  return response.data;
};

export const getOrgUsers = async () => {
  const response = await axios.get<User[]>(
    buildUrl(endpoints.getOrgUsers()),
    {
      headers: buildHeaders(),
    }
  );
  return response.data;
};

interface OrgAddUserParams {
  firstName?: string;
  lastName?: string;
  email: string;
  isOrgAdmin: boolean;
}
export const orgAddUser = async ({
  firstName,
  lastName,
  email,
  isOrgAdmin,
}: OrgAddUserParams) => {
  const response = await axios.post<User>(
    buildUrl(endpoints.createOrgUser()),
    {
      firstName,
      lastName,
      email,
      isOrgAdmin,
    },
    {
      headers: buildHeaders(),
    }
  );
  return response.data;
};

interface OrgEditUserParams {
  id: string;
  firstName: string;
  lastName: string;
  isOrgAdmin: boolean;
}
export const orgEditUser = async ({
  id,
  firstName,
  lastName,
  isOrgAdmin,
}: OrgEditUserParams) => {
  const response = await axios.post<User>(
    buildUrl(endpoints.editOrgUser()),
    {
      id,
      firstName,
      lastName,
      isOrgAdmin,
    },
    {
      headers: buildHeaders(),
    }
  );
  return response.data;
};

export const getOrganization = async () => {
  const response = await axios.get<Organization>(
    buildUrl(endpoints.getOrganization()),
    {
      headers: buildHeaders(),
    }
  );
  return response.data;
};

export const setUserPassword = async (password: string) => {
  const response = await axios.post<Organization>(
    buildUrl(endpoints.setNewPassword()),
    {
      password
    },
    {
      headers: buildHeaders(),
    }
  );
  return response.data;
};


interface DeleteUserParams {
  id: string;
}
export const deleteUser = async ({
  id,
}: DeleteUserParams) => {
  const response = await axios.post(
    buildUrl(endpoints.deleteUser()),
    {
      id,
    },
    {
      headers: buildHeaders(),
    }
  );
  return response.data;
};