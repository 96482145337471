import {
  Box,
  BoxProps,
  Button,
  Heading,
  Stack,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import * as React from "react";
import { useQuery } from "react-query";
import { downloadCSV, getPatients } from "../../../api";
import { Patients } from "./components/Patients";
import { NewPatientPopup } from "./components/NewPatientPopup";

function Home() {
  const {
    isLoading,
    data = [],
    isError,
    // error,
    refetch,
  } = useQuery<Patient[], Error>("patients", getPatients);

  const onDownloadCSV = async () => {
    const data = await downloadCSV();
    const csvString = data.csvData;
    if (!csvString) {
      alert('Failed to generate the CSV Report');
      return;
    }
    const a = document.createElement('a');
    const date = new Date().toISOString().split("T")[0];
    a.download = `RxGames Data Export ${new Date().toLocaleDateString().replaceAll('/','-')}.csv`
    a.href = URL.createObjectURL(new Blob([csvString], { type: 'text/csv' }));
    a.click();
  }

  return (
    <Stack spacing={{ base: "8", lg: "6" }}>
      <Stack
        spacing="4"
        direction={{ base: "column", lg: "row" }}
        alignItems="flex-end"
        justify="space-between"
      >
        <Heading
          size={useBreakpointValue({ base: "sm", lg: "md" })}
          fontWeight="medium"
        >
          All Clients
        </Heading>
        <Button onClick={onDownloadCSV}>Download All Client Activity Report</Button>
        <NewPatientPopup onPatientAdded={() => refetch()} />
      </Stack>
      <Card>
        <Patients patients={data} isLoading={isLoading} isError={isError} />
      </Card>
    </Stack>
  );
}

const Card = (props: BoxProps) => (
  <Box
    minH="36"
    bgColor={useColorModeValue("white", "gray.800")}
    boxShadow={useColorModeValue("sm", "sm-dark")}
    borderRadius="lg"
    {...props}
  />
);

export default Home;
