import * as React from "react";
import {
  Flex,
  Box,
  BoxProps,
  Image,
  Text,
  Badge,
  Tag,
  useColorModeValue,
} from "@chakra-ui/react";
import { formatDuration } from "../../../../../lib/util";

interface GameCardProps {
  gameConfig: GameConfig;
}

export const GameCard = ({ gameConfig }: GameCardProps) => {
  const { sets, duration, thumb1x, thumb2x } = gameConfig;
  console.log({ gameConfig });

  let gameImage = {};
  if (thumb1x) {
    gameImage = {
      backgroundImage: `url(${thumb1x})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
    };
  } else {
    gameImage = {
      bgColor: "brand.green",
    };
  }

  return (
    <Card>
      <Flex
        h={175}
        p={4}
        borderTopLeftRadius="lg"
        borderTopRightRadius="lg"
        justifyContent="right"
        alignItems="flex-end"
        {...gameImage}
      >
        <Badge bgColor="white">
          {sets}x{formatDuration(duration)}
        </Badge>
      </Flex>
      <Box p={4}>
        <Text fontSize="md" fontWeight="bold">
          {gameConfig?.name}
        </Text>
        <Text fontSize="sm" color="gray.500">
          {gameConfig?.description}
        </Text>
        <Exercises exercises={gameConfig?.exercises} />
      </Box>
    </Card>
  );
};

const Exercises = ({ exercises }: { exercises: string[] | undefined }) => {
  if (!exercises || exercises.length === 0) return null;

  const MAX_EXERCISES = 3;

  // Build badges for initially visible items
  let items = [];
  for (let i = 0; i < exercises.length && i < MAX_EXERCISES; i++) {
    const exercise = exercises[i];
    items.push(
      <Tag key={exercise} fontSize="xs">
        {exercise}
      </Tag>
    );
  }

  // If there are more items than the max, add a badge to indicate that
  if (exercises.length > MAX_EXERCISES) {
    items.push(
      <Tag key="more" fontSize="xs">
        +{exercises.length - MAX_EXERCISES} more
      </Tag>
    );
  }

  return (
    <Flex mt={2} gap={1} flexWrap="wrap">
      {items}
    </Flex>
  );
};

const Card = (props: BoxProps) => (
  <Box
    minH="36"
    bgColor={useColorModeValue("white", "gray.800")}
    boxShadow={useColorModeValue("sm", "sm-dark")}
    borderRadius="lg"
    {...props}
  />
);
