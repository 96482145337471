import React from "react";
import { Stack, Spinner } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { getGames } from "../../../../api";
import GameItem from "./GameItem";
import { isMatch } from "../../../../lib/filters";

interface GamesChooserProps {
  filters: Filters;
  onGamesAdded?: (games: GameConfig[]) => void;
}

const GamesChooser = React.memo(
  ({ filters, onGamesAdded }: GamesChooserProps) => {
    const { isLoading, isSuccess, data } = useQuery("games", getGames);

    const handleGameAdded = (
      game: Game,
      totalSets: number,
      duration: number
    ) => {
      if (onGamesAdded) {
        let games = [];
        for (let i = 0; i < totalSets; i++) {
          games.push({ gameId: Number(game.id), duration });
        }
        onGamesAdded(games);
      }
    };

    const renderGamesList = () => {
      if (isLoading) {
        return <Spinner />;
      }

      if (isSuccess) {
        const filteredGames = data.filter((game) => {
          if (game && game.tags && filters) {
            return isMatch(filters, game);
          }
          return false;
        });

        return filteredGames.map((game) => (
          <GameItem key={game.id} game={game} onGameAdded={handleGameAdded} />
        ));
      }
    };

    return <Stack spacing={8}>{renderGamesList()}</Stack>;
  }
);

export default GamesChooser;
