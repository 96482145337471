import { SignIn } from "@clerk/clerk-react";
import { Link as ReactLink } from "react-router-dom";
import { Container, Flex, Stack } from "@chakra-ui/react";
import { Footer } from "../../components/Footer";
import styled from "@emotion/styled";
function Login({ className }: { className?: string }) {
  return (
    <Container
      className={`${className} test22`}
      maxW="md"
      py={{ base: "12", md: "24" }}
    >
      <Stack spacing="8">
        <SignIn
        />
        <Flex className="forgot-password-link-row">
          <span className="custom-clerk-link-label">
            Forgot your password?
          </span>
          <ReactLink to="/forgot-password" className="custom-clerk-link">
            Click here
          </ReactLink>
        </Flex>
      </Stack>
      <Footer />
    </Container>
  );
}

export default styled(Login)`
  .cl-internal-b3fm6y {
    display: none;
  }

  .forgot-password-link-row {
    display: flex;
    justify-content: center;
    margin-bottom: 1em !important;
  }
`;
