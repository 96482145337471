import * as React from "react";

import { Tag } from "@chakra-ui/react";

interface TagsProps {
  tags: string[] | undefined;
}

export const Tags = ({ tags }: TagsProps) => {
  if (!tags || tags.length === 0) return null;

  return (
    <>
      {tags.map((tag) => (
        <Tag fontSize="xs" mr={2} key={tag}>
          {tag}
        </Tag>
      ))}
    </>
  );
};
