import { Box, Flex, Text } from "@chakra-ui/layout";
import { useState, useCallback, useEffect } from "react";
import {
  addPrescription,
  editPrescription,
  updatePrescription,
} from "../../../../api";
import GamesChooser from "./GamesChooser";
import Filters from "./Filters";
import Overview from "./Overview";
import Presets from "./Presets";
import { Button, Grid, Input, Stack } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";

interface PrescriptionBuilderProps {
  patientId: string;
}

const MAX_NAME_LENGTH: number = 128;

const PrescriptionBuilder = ({ patientId }: PrescriptionBuilderProps) => {
  // Constants

  const navigate = useNavigate();
  const { prescriptionId } = useParams<{ prescriptionId: string }>();

  // Queries

  const { data: existingPrescription } = useQuery<Prescription>(
    ["prescription", patientId, prescriptionId],
    () => {
      return editPrescription(patientId, prescriptionId);
    }
  );

  // State

  const [filters, setFilters] = useState<Filters>({});
  const [name, setName] = useState<string>("");
  const [games, setGames] = useState<GameConfig[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  // Effects

  useEffect(() => {
    if (existingPrescription) {
      setName(existingPrescription?.name || "");
      setGames(existingPrescription?.games || []);
    }
  }, [existingPrescription]);

  // Handlers

  const handleFiltersUpdate = (filters: any) => {
    setFilters(filters);
  };

  const handleGamesAdded = useCallback(
    (gameConfigs: GameConfig[]) => {
      console.log(gameConfigs);
      setGames([...games, ...gameConfigs]);
    },
    [games]
  );

  const handleGameRemoved = (gameId: number, duration: number) => {
    const filteredGames = Object.assign([], games).filter(
      (game: GameConfig) => {
        const keep = game.gameId === gameId && game.duration === duration;
        console.log({
          keep,
          id: game.gameId,
          gameId,
          dur: game.duration,
          duration,
        });
        return !keep;
      }
    );
    setGames(filteredGames);
  };

  const handlePresetApply = (preset: Preset | undefined) => {
    if (preset) {
      setGames(preset.games);
    }
  };

  const handleSave = async () => {
    console.log({ name, games, existingPrescription });
    setLoading(true);

    // Check if name is set, show error
    if (!name || name.length <= 0) {
      setError("Please enter a name for the prescription");
      setLoading(false);
      return;
    }

    if (name.length > MAX_NAME_LENGTH) {
      setError("Name cannot be longer than 128 characters");
      setLoading(false);
      return;
    }

    try {
      // Build prescription payload
      const prescription: Prescription = {
        name,
        games,
      };

      let response = null;
      if (existingPrescription && existingPrescription.id) {
        // Update existing prescription (ie. create a copy and archive the original)
        prescription.id = existingPrescription.id;
        response = await updatePrescription(prescription, patientId);
      } else {
        // Save new prescription to API
        response = await addPrescription(prescription, patientId);
      }

      setLoading(false);
      if (response && response.id) {
        navigate(-1);
      }
    } catch (error) {
      console.error("Error saving prescription: ", error);
      setError("Could not save prescription. Please try again.");
      setLoading(false);
      return;
    }
  };

  const nameHasError = name.length > MAX_NAME_LENGTH;

  return (
    <Box>
      <Grid templateColumns="300px 1fr" templateRows="1fr" columnGap={8}>
        <Box flex={1}>
          <Stack spacing={4} bgColor="white" p={8} mb={10}>
            <Text fontWeight="bold">Basic Info</Text>
            <Box>
              <Input
                type="text"
                placeholder="Prescription Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                color={nameHasError ? "brand.red" : "brand.black"}
                borderColor={nameHasError ? "brand.red" : "brand.gray"}
              />
              <Text
                color={nameHasError ? "brand.red" : "brand.gray"}
                fontSize="xs"
                mt={1}
                textAlign="right"
              >
                {name.length}/128
              </Text>
            </Box>

            <Flex gap="3" justifyContent="flex-end">
              <Button variant="outline" onClick={() => navigate(-1)}>
                Cancel
              </Button>
              <Button
                variant="solid"
                onClick={handleSave}
                disabled={games.length <= 0}
                isLoading={loading}
              >
                Save
              </Button>
            </Flex>
            {error && error.length > 0 && (
              <Text
                fontSize="sm"
                color="brand.red"
                textAlign="center"
                fontWeight="bold"
              >
                {error}
              </Text>
            )}
          </Stack>
          <Overview games={games} onRemoveGame={handleGameRemoved} />
          <Presets onApply={handlePresetApply} />
          <Filters onFiltersChange={handleFiltersUpdate} />
        </Box>
        <Box flex={2}>
          <GamesChooser filters={filters} onGamesAdded={handleGamesAdded} />
        </Box>
      </Grid>
    </Box>
  );
};

export default PrescriptionBuilder;
