import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  Text,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Select,
  Checkbox,
  Switch,
} from "@chakra-ui/react";
import * as React from "react";
import { useMutation } from "react-query";
import { rxAddOrgUser, downloadCSV } from "../../../../api";

interface NewUserPopupProps {
  orgList: Organization[];
  onUserAdded?: () => void;
}

export const NewUserPopup = ({orgList, onUserAdded }: NewUserPopupProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [orgPk, setOrgPk] = React.useState("");
  const [isOrgAdmin, setIsOrgAdmin] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const initialRef = React.useRef(null);

  const { mutate, isLoading } = useMutation(rxAddOrgUser, {
    onSuccess: (response: any) => {
      if (response.error) {
        setError(response.error);
        return;
      }
      onClose();
      if (onUserAdded) onUserAdded();
      setFirstName('');
      setLastName('');
      setEmail('');
      setOrgPk('');
      setIsOrgAdmin(false);
    },
    onError: (err) => {
      setError("Sorry, new client could not be added.");
    },
  });

  const renderError = () => {
    if (error) {
      return (
        <Text color="red" mt={4}>
          {error}
        </Text>
      );
    }
    return null;
  };

  const handleAddClick = async () => {
    setError(null);

    if (!email) {
      setError("Please enter the user's email address");
      return;
    }

    mutate({ firstName, lastName, email, orgPk, isOrgAdmin });
  };

  return (
    <>
      <Button minWidth="auto" onClick={onOpen}>New Provider Account</Button>
      <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Provider Account</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Email address</FormLabel>
              <Input
                ref={initialRef}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>First Name</FormLabel>
              <Input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Last Name</FormLabel>
              <Input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Organization</FormLabel>
              <Select
                value={orgPk}
                onChange={(e) => setOrgPk(e.target.value)}
              >
                <option></option>
                {orgList.map(org => (
                  <option value={org.pk}>{org.name}</option>
                ))}
              </Select>
            </FormControl>
            
            <FormControl mt={4}>
              <FormLabel>Is Admin for their Organization?</FormLabel>
              <Switch
                type="checkbox"
                isChecked={isOrgAdmin}
                onChange={(e) => {
                  setIsOrgAdmin(!isOrgAdmin)
                }}
              />
            </FormControl>

            {renderError()}
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={handleAddClick}
              isLoading={isLoading}
            >
              Add
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
