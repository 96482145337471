import {
  Flex,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  TableContainer,
  Spinner,
  Icon,
  Button,
  ButtonGroup,
} from "@chakra-ui/react";
import { FaChevronRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { EditUserPopup } from "./EditUserPopup";
import React from "react";
import { getOrganization } from "../../../../api";
import { useQuery } from "react-query";

interface UsersProps {
  Users: User[];
  isLoading?: boolean;
  isError?: boolean;
  refetch: Function;
}

export const Users = ({ Users, isLoading, isError, refetch }: UsersProps) => {
  const {
    data: orgList = [],
  } = useQuery<Organization, Error>("Organization", getOrganization);
  const [userUnderEdit, setUserUnderEdit] = React.useState<User | null>(null);
  const [currentPage, setCurrentPage] = React.useState<number>(0);

  let pageSize = 20;

  let tableContent;
  if (isError) {
    tableContent = (
      <Tr text-align="center" >
        <Td colSpan={3}
          paddingY="20px"
          textAlign="center"
          fontSize="sm"
          color="brand.red"
          fontWeight="bold"
        >Sorry, we are unable to load clients due to an error.</Td>
      </Tr >
    );
  }
  else if (isLoading) {
    tableContent = (
      <Tr text-align="center" >
        <Td colSpan={3} paddingY="20px" textAlign="center"><Spinner /></Td>
      </Tr >
    );
  } else {
    tableContent = (
      Users.sort((a, b) => (a.lastName == b.lastName ? (`${a.email}` < `${b.email}` ? -1 : 1) : (`${a.lastName}` < `${b.lastName}` ? -1 : 1))).slice(currentPage * pageSize, currentPage * pageSize + pageSize).map((User: User) => (
        <UserRow
          key={User.id}
          id={User.id}
          email={User.email}
          firstName={User.firstName || ""}
          lastName={User.lastName || ""}
          orgPk={User.orgPk || ""}
          type={User.type || 0}
          isRxAdmin={!!User.isRxAdmin}
          isOrgAdmin={!!User.isOrgAdmin}
          onClicked={(user: User) => {
            setUserUnderEdit(user);
          }}
        />
      ))
    );
  }

  return (
    <>
      {!!userUnderEdit && (<EditUserPopup user={userUnderEdit} onUserEdited={(succeeded: boolean) => { setUserUnderEdit(null); succeeded && refetch(); }} />)}
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Type</Th>
              <Th>Is Admin</Th>
              <Th textAlign="right"></Th>
            </Tr>
          </Thead>
          <Tbody>
            {tableContent}
          </Tbody>
        </Table>
      </TableContainer>
      {Users.length > pageSize && <ButtonGroup display="flex" justifyContent="space-around" alignItems="center" padding="16px">
        <Button onClick={() => setCurrentPage(Math.max(currentPage - 1, 0))}>Previous Page</Button>
        Page {currentPage + 1} of {Math.ceil(Users.length / pageSize)}
        <Button onClick={() => setCurrentPage(Math.min(currentPage + 1, Math.ceil(Users.length / pageSize) - 1))}>Next Page</Button>
      </ButtonGroup>}
    </>
  );
};

interface UserProps {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  orgPk: string;
  type: number;
  isRxAdmin: boolean;
  isOrgAdmin: boolean;
  onClicked: Function;
}

const stringUserType = (type: number) => {
  switch (type) {
    case 0: return 'Client';
    case 1: return 'Therapist';
    case 2: return 'Admin (Unused)';
    default: return 'Undefined';
  }
}

const UserRow = ({
  id,
  firstName,
  lastName,
  email,
  type,
  isRxAdmin,
  isOrgAdmin,
  onClicked,
  orgPk,
}: UserProps) => {
  const navigate = useNavigate();
  return (
    <Tr onClick={() => onClicked({ id, firstName, lastName, orgPk, type, isRxAdmin, isOrgAdmin })} cursor="pointer">
      <Td>
        {lastName}, {firstName}
      </Td>
      <Td>{email}</Td>
      <Td>{stringUserType(type)}</Td>
      <Td>{`${!!isOrgAdmin}`}</Td>
      {/* <Td>{`${!!isRxAdmin}`}</Td> */}
      <Td textAlign="right">
        <Icon as={FaChevronRight} />
      </Td>
    </Tr>
  );
};
