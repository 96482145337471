import { Flex, Grid, Stack, Text } from "@chakra-ui/react";
import React from "react";
import Moment from "moment";
import { formatDuration, groupGamesBySets } from "../../../../../lib/util";
import { GameCard } from "./GameCard";
import { OptionsMenu } from "./OptionsMenu";

interface PrescriptionProps {
  prescription: Prescription;
}

export const Prescription = ({ prescription }: PrescriptionProps) => {
  const { id, patientId, name, games, updatedAt } = prescription;
  const createDate = Moment(updatedAt).fromNow();

  if (!id || !patientId) {
    return null;
  }

  console.log({ id });

  const calculateTotalTime = (): number => {
    return games.reduce((total, game) => {
      return total + game.duration;
    }, 0);
  };

  const totalTime = calculateTotalTime();

  return (
    <Flex bgColor="gray.100" p={6} borderRadius="lg" gap={6} flexDir="column">
      <Flex justifyContent="space-between">
        <Flex gap={2}>
          <Stack flex={1}>
            <Text fontWeight="bold" fontSize="xl" p={0} flex={1}>
              {name}
            </Text>
            <Text color="brand.gray" fontSize="sm">
              {formatDuration(totalTime)} total
            </Text>
          </Stack>
          <OptionsMenu prescriptionId={id} patientId={patientId} />
        </Flex>

        <Text color="gray.500" fontSize="xs">
          Last updated {createDate}
        </Text>
      </Flex>

      <Grid templateColumns="repeat(3, 1fr)" gap={6}>
        {groupGamesBySets(games).map((game) => (
          <GameCard key={game.gameId} gameConfig={game} />
        ))}
      </Grid>
    </Flex>
  );
};
