import {
  Box,
  BoxProps,
  Heading,
  Spinner,
  Stack,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import * as React from "react";
import { useQuery } from "react-query";
import { getOrgUsers, getOrganization, getUser } from "../../../api";
import { NewOrgUserPopup } from "./components/NewOrgUserPopup";
import { Users } from "./components/Users";

function OrganizationPage() {
  const { data: user } = useQuery<User>("user", getUser);
  const { data: users, isLoading, refetch, isError } = useQuery<User[]>("OrgUsers", getOrgUsers);
  const { data: organization } = useQuery<Organization>('Organization', getOrganization);

  const size = useBreakpointValue({ base: "sm", lg: "md" });

  console.log("OK: ", isLoading, user, users, organization);

  if (isLoading || !user || !users || !organization) return (<Spinner></Spinner>)

  if (!user || !user.isOrgAdmin) return (<Stack>
    <div>Access Denied</div>
  </Stack>);

  return (
    <Stack spacing={{ base: "8", lg: "6" }}>
      <Stack
        spacing="4"
        direction={{ base: "row", lg: "row" }}
        alignItems="flex-end"
        justify="space-between"
      >
        <Heading
          size={size}
          fontWeight="medium"
        >
          Your Organization: {organization.name}
        </Heading>
        <Stack direction="row" spacing="3">
          {/* <Button variant="outline">Download</Button> */}
          <NewOrgUserPopup organization={organization} onUserAdded={() => setTimeout(() => refetch(), 1000)} />
        </Stack>
      </Stack>
      <Card>
        <Users Users={users} isLoading={isLoading} isError={isError} refetch={refetch} />
      </Card>
    </Stack>
  );
}

const Card = (props: BoxProps) => (
  <Box
    minH="36"
    bgColor={useColorModeValue("white", "gray.800")}
    boxShadow={useColorModeValue("sm", "sm-dark")}
    borderRadius="lg"
    {...props}
  />
);

export default OrganizationPage;
