import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import * as React from "react";
import { useQueryClient } from "react-query";
import { archivePrescription, editPrescription } from "../../../../../api";
import { FaEllipsisH, FaArchive, FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

interface OptionsMenuProps {
  prescriptionId: string;
  patientId: string;
}

export const OptionsMenu = ({
  prescriptionId,
  patientId,
}: OptionsMenuProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<any>(null);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleArchive = async () => {
    console.log({ patientId, prescriptionId });
    try {
      await archivePrescription(patientId, prescriptionId);
      queryClient.invalidateQueries("patient");
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditPrescription = async () => {
    console.log({ patientId, prescriptionId });
    try {
      const existingPrescription = await editPrescription(
        patientId,
        prescriptionId
      );
      if (existingPrescription) {
        navigate(
          `/clients/${patientId}/prescriptions/${prescriptionId}/edit`,
          {
            state: { existingPrescription },
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<FaEllipsisH />}
          variant="ghost"
          size="sm"
        />
        <MenuList>
          <MenuItem icon={<FaEdit />} onClick={handleEditPrescription}>
            Edit
          </MenuItem>
          <MenuItem icon={<FaArchive />} onClick={onOpen}>
            Archive
          </MenuItem>
        </MenuList>
      </Menu>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Archive Prescription
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to archive this prescription? This will
              remove from the patient's active prescriptions.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button variant="outline" ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button bg="red.500" onClick={handleArchive} ml={3}>
                Archive
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
