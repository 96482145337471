import * as React from "react";

import { Tag } from "@chakra-ui/react";

interface PositionsProps {
  positions: string[] | undefined;
}

export const Positions = ({ positions }: PositionsProps) => {
  if (!positions || positions.length === 0) return null;

  return (
    <>
      {positions.map((position) => (
        <Tag fontSize="xs" mr={2} key={position} bgColor="green.100">
          {position}
        </Tag>
      ))}
    </>
  );
};
