import type { FC } from "react";
import { clearToken } from "../lib/store";
import { Box, Button, Container, Flex } from "@chakra-ui/react";
import { Navbar } from "../containers/Dashboard/components/Navbar";
import { useNavigate } from "react-router-dom";

interface PatientBlockerProps {
  user: User | undefined;
}

export const PatientBlocker: FC<PatientBlockerProps> = (props) => {
  const { user } = props;
  const navigate = useNavigate();

  const handleSignOut = () => {
    clearToken();
    navigate("/login");
  };

  return (
    <Box className="Home" as="section" bg={{ base: "gray.50" }} minH="100vh">
      <Navbar user={user} />
      <Container
        maxW="6xl"
        pt={{ base: 8, lg: 12 }}
        pb={{ base: 12, lg: 24 }}
        px={{ base: 4, md: 8, xl: 0 }}
      >
        <Flex marginBottom="20px" justifyContent="center" flexDirection="column" alignItems="center">
          <div style={{ marginBottom: '1rem' }}>Sorry, this web site is for provider users only, but your user type is set to client.</div>
          <div>If you are a provider and this is an error, please contact RxGames at <a style={{ color: "#4b6eff" }} href="mailto:support@rxgames.com">support@rxgames.com</a> to have this fixed.</div>
        </Flex>
      </Container>
    </Box>
  );
};
