import {
  Flex,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  TableContainer,
  Spinner,
  Icon,
} from "@chakra-ui/react";
import { FaChevronRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

interface PatientsProps {
  patients: Patient[];
  isLoading?: boolean;
  isError?: boolean;
}

export const Patients = ({ patients, isLoading, isError }: PatientsProps) => {
  let tableContent;
  if (isError) {
    tableContent = (
      <Tr text-align="center" >
        <Td colSpan={3}
          paddingY="20px"
          textAlign="center"
          fontSize="sm"
          color="brand.red"
          fontWeight="bold"
        >Sorry, we are unable to load clients due to an error.</Td>
      </Tr >
    );
  }
  else if (isLoading) {
    tableContent = (
      <Tr text-align="center" >
        <Td colSpan={3} paddingY="20px" textAlign="center"><Spinner /></Td>
      </Tr >
    );
  } else if (!patients.length) {
    tableContent = (
      <Tr text-align="center" >
        <Td colSpan={3}
          paddingY="20px"
          textAlign="center"
          fontSize="sm"
        >No clients found, please add a New Client using the button above.</Td>
      </Tr >
    );
  } else {
    tableContent = (
      patients.map((patient: Patient) => (
        <PatientRow
          key={patient.id}
          id={patient.id}
          externalId={patient.externalId}
          firstName={patient.firstName || ""}
          lastName={patient.lastName || ""}
          username={patient.username || ""}
        />
      ))
    );
  }

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Username</Th>
            <Th>External ID</Th>
            <Th textAlign="right"></Th>
          </Tr>
        </Thead>
        <Tbody>
          {tableContent}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

interface PatientProps {
  id: string;
  externalId: string;
  firstName: string;
  lastName: string;
  username: string;
}

const PatientRow = ({
  id,
  externalId,
  firstName,
  lastName,
  username,
}: PatientProps) => {
  const navigate = useNavigate();
  return (
    <Tr onClick={() => navigate(`/clients/${id}`)} cursor="pointer">
      {/* <Td>
        {lastName}, {firstName}.
      </Td> */}
      <Td>{username}</Td>
      <Td>{externalId}</Td>
      <Td textAlign="right">
        <Icon as={FaChevronRight} />
      </Td>
    </Tr>
  );
};
