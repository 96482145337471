import _ from "lodash";

export const generateId = () => {
  // Return a random ID with numbers and letters
  return Math.random().toString(36).substr(2, 9);
};

export const groupGamesBySets = (games: GameConfig[]) => {
  return _(games)
    .groupBy((g) => `${g.gameId}_${g.duration}`)
    .map((value, key) => {
      return {
        id: key,
        sets: value.length,
        ...value[0],
      };
    })
    .value();
};

export const formatDuration = (duration: number): string => {
  if (duration <= 0) {
    return "Not set";
  }
  if (duration <= 90) {
    return `${duration} seconds`;
  }
  const minutes = Math.floor(duration / 60);
  return `${minutes} minutes`;
};

export const formatTotalTime = (duration: number): string => {
  if (duration <= 0) {
    return "0 sec";
  }
  if (duration <= 90) {
    return `${duration} sec`;
  }
  const minutes = Number((duration / 60).toFixed(2));
  return `${minutes} min`;
};
