// import { ResetPassword } from "@clerk/clerk-react";
import { Button, Card, Container, Input, Stack, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useSignIn } from "@clerk/clerk-react";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Footer } from "../../components/Footer";
import axios from "axios";
import { buildUrl, endpoints, setUserPassword } from "../../api";

function SetPassword({ className }: { className?: string }) {

  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  async function onSubmit(e: React.FormEvent) {
    if (password != confirmPassword) {
      alert("The password and confirmed password must match!");
      return;
    }
    e.preventDefault();
    try {
      const response = await setUserPassword(password);
      console.log('Got response: ', response);
    } catch (e: any) {
      console.log('Caught error: ', e);
      if (e.errors && e.errors.length) {
        setError(e.errors[0].longMessage);
      } else {
        alert('An error occurred, unable to set your password at this time.');
      }
    }
  }

  return (
    <Container
      className={`${className} test22`}
      maxW="md"
      py={{ base: "12", md: "24" }}
    >
      <Stack spacing="8">
        <Card className="fp-card-content">
          <div className="fp-logo-container">
            <img src="/logo/rxgames_logo.avif" alt="RxGames logo" />
          </div>
          <h1 className="fp-title">
            Welcome to RxGames!
          </h1>
          <h1 className="fp-title">
            Please Create Your Password
          </h1>
          <div className="fp-field-container">
            <label className="fp-label">
              Password
            </label>
            <Input
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <label className="fp-label">
              Confirm Password
            </label>
            <Input
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          {error ? (<Text color="red">{error}</Text>) : null}
          <Button onClick={onSubmit}>Set Password</Button>
        </Card>
      </Stack>
      <Footer />
    </Container>
  );
}

export default styled(SetPassword)`
  .cl-internal-b3fm6y {
    display: none;
  }

  .fp-card-content {
    box-sizing: inherit;
    display: flex;
    flex-flow: column;
    -moz-box-align: stretch;
    align-items: stretch;
    -moz-box-pack: start;
    justify-content: flex-start;
    gap: 2rem;
    will-change: transform, opacity, height;
    border-radius: 1rem;
    background-color: white;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-duration: 200ms;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 24px 48px;
    border: 1px solid transparent;
    width: 25rem;
    max-width: calc(-5rem + 100vw);
    margin: 0px 1.75rem;
    padding: 2.375rem 2rem 3rem;
  }

  .fp-label {
    color: black;
    font-family: inherit;
    font-weight: 500;
    font-size: 0.8125rem;
    line-height: 1.375;
    display: flex;
    -moz-box-align: center;
    align-items: center;
  }

  .fp-logo-container {
    height: 24px;
    display: flex;
  }
  .fp-logo-container img {
    height: 24px;
  }

  .fp-title {
    box-sizing: border-box;
    color: black;
    margin: 0px;
    font-family: inherit;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.5;
  }

  .fp-login-link {
    box-sizing: border-box;
    display: inline-flex;
    -moz-box-align: center;
    align-items: center;
    margin: 0px;
    cursor: pointer;
    text-decoration: none;
    font-family: inherit;
    font-weight: 400;
    font-size: 0.8125rem;
    line-height: 1.25;
    color: rgb(16, 63, 239);
  }
  .fp-login-link:hover {
    text-decoration: underline;
    color: rgb(59, 98, 242);
  }
  .fp-login-link-label {
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.65);
    margin: 0px;
    font-size: 0.8125rem;
    font-family: inherit;
    font-weight: 400;
    line-height: 1.25;
    margin-right: 0.25em;
  }

  .fp-field-container {
    display: flex;
    flex-flow: column;
    gap: 4px;
  }
`;
