import { useParams } from "react-router-dom";
import PrescriptionBuilder from "./components/PrescriptionBuilder";

const PrescriptionNew = () => {
  // const navigate = useNavigate();
  const { id = "" } = useParams<{ id: string }>();

  // const {
  //   data: patient,
  // } = useQuery<Patient | null>("patient", () => {
  //   return getPatientById(id);
  // });
  // const patientFullName = `${patient?.firstName} ${patient?.lastName}`;

  return <PrescriptionBuilder patientId={id} />;
};

export default PrescriptionNew;
