import {
  Box,
  BoxProps,
  Heading,
  Spinner,
  Stack,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import * as React from "react";
import { useQuery } from "react-query";
import { getOrganizations, getUser } from "../../../api";
import { Organizations } from "./components/Organizations";
import { NewOrganizationPopup } from "./components/NewOrganizationPopup";
import { EditOrganizationPopup } from "./components/EditOrganizationPopup";

function OrganizationsPage() {
  const {
    isLoading,
    data = [],
    isError,
    // error,
    refetch,
  } = useQuery<Organization[], Error>("Organizations", getOrganizations);
  const { data: user } = useQuery<User>("user", getUser);

  const size = useBreakpointValue({ base: "sm", lg: "md" });

  if (isLoading) return (<Spinner></Spinner>)

  if (!user || !user.isRxAdmin) return (<Stack>
    <div>Access Denied</div>
  </Stack>);

  return (
    <Stack spacing={{ base: "8", lg: "6" }}>
      <Stack
        spacing="4"
        direction={{ base: "column", lg: "row" }}
        alignItems="flex-end"
        justify="space-between"
      >
        <Heading
          size={size}
          fontWeight="medium"
        >
          All Organizations
        </Heading>
        <Stack direction="row" spacing="3">
          {/* <Button variant="outline">Download</Button> */}
          <NewOrganizationPopup onOrganizationAdded={() => refetch()} />
        </Stack>
      </Stack>
      <Card>
        <Organizations Organizations={data} isLoading={isLoading} isError={isError} refetch={refetch} />
      </Card>
    </Stack>
  );
}

const Card = (props: BoxProps) => (
  <Box
    minH="36"
    bgColor={useColorModeValue("white", "gray.800")}
    boxShadow={useColorModeValue("sm", "sm-dark")}
    borderRadius="lg"
    {...props}
  />
);

export default OrganizationsPage;
