import * as React from "react";

import { Flex, Tag } from "@chakra-ui/react";

interface ExercisesProps {
  exercises: string[] | undefined;
}

export const Exercises = ({ exercises }: ExercisesProps) => {
  if (!exercises || exercises.length === 0) return null;

  return (
    <>
      {exercises.map((exercise) => (
        <Tag fontSize="xs" mr={2} key={exercise} bgColor="blue.100">
          {exercise}
        </Tag>
      ))}
    </>
  );
};
