export const config = {
  // title: {
  //   text: "Shoulder abduction left (elbow angle)",
  // },
  colors: [
    "#4b6eff", // blue
    "#00e6a5", // green
    "#ff5a00", // orange
  ],
  chart: {
    id: "chart",
    toolbar: {
      export: {
        csv: {
          headerCategory: 'Repetition',
          headerValue: 'Measurement',
        }
      }
    }
  },
  xaxis: {
    // title: {
    //   text: "Time (days)",
    // },
    //tickAmount: 10,
    labels: {
      show: false,
      formatter: function (val: string) {
        const date = new Date(val);
        // Format date to be mmm dd, yyyy
        // return `${date.toLocaleString("default", {
        //   month: "short",
        // })} ${date.getDate()}, ${date.getFullYear()}`;
        return val;
      },
    },
  },
  yaxis: {
    forceNiceScale: true,
    decimalsInFloat: 0,
    title: {
      text: "",
      offsetX: -5,
      style: {
        fontSize: "16px",
      },
    },
    labels: {
      style: {
        fontSize: "16px",
      },
    },
  },
};

export const types = [
  {
    id: 1,
    dropdownName: "Shoulder abduction left (elbow angle)",
    gestureType: "shoulder-abduction-left",
    metricId: "left-elbow-angle",
    minMax: "max",
    yLabel: "Max elbow angle",
    trendLineLabel: "",
  },
  {
    id: 2,
    dropdownName: "Elbow flexion left",
    gestureType: "elbow-extension-flexion-left, elbow-flexion-left",
    metricId: "left-elbow-angle",
    minMax: "delta",
    yLabel: "Range of motion (max - min degrees)",
    trendLineLabel: "",
  },
  {
    id: 3,
    dropdownName: "Hip flexion straight leg left",
    gestureType: "hip-flexion-left",
    metricId: "left-hip-angle-yz",
    minMax: "max",
    yLabel: "Max hip angle from vertical",
    trendLineLabel: "",
  },
  {
    id: 4,
    dropdownName: "Hip abduction left",
    gestureType: "hip-abduction-left",
    metricId: "left-hip-ankle-angle-xy",
    minMax: "max",
    yLabel: "Max hip angle from vertical",
    trendLineLabel: "",
  },
  {
    id: 5,
    dropdownName: "Squat (left knee)",
    gestureType: "squat, squat-up",
    metricId: "left-knee-angle",
    minMax: "min",
    yLabel: "Min knee angle",
    trendLineLabel: "",
  },
  {
    id: 6,
    dropdownName: "Shoulder abduction left",
    gestureType: "shoulder-abduction-left",
    metricId: "left-shoulder-hand-angle-xy",
    minMax: "max",
    yLabel: "Max shoulder-hand angle from vertical",
    trendLineLabel: "",
  },
  {
    id: 7,
    dropdownName: "Shoulder flexion left",
    gestureType:
      "shoulder-flexion-elbow-extension-bimanual, shoulder-flexion-elbow-extension-left, shoulder-flexion-elbow-extension-left",
    metricId: "left-shoulder-hand-angle-yz",
    minMax: "max",
    yLabel: "Max shoulder-hand angle from vertical",
    trendLineLabel: "",
  },
  {
    id: 8,
    dropdownName: "Shoulder abduction right (elbow angle)",
    gestureType: "shoulder-abduction-right",
    metricId: "right-elbow-angle",
    minMax: "max",
    yLabel: "Max elbow angle",
    trendLineLabel: "",
  },
  {
    id: 9,
    dropdownName: "Elbow flexion right",
    gestureType: "elbow-extension-flexion-right, elbow-flexion-right",
    metricId: "right-elbow-angle",
    minMax: "delta",
    yLabel: "Range of motion (max - min degrees)",
    trendLineLabel: "",
  },
  {
    id: 10,
    dropdownName: "Hip flexion straight leg right",
    gestureType: "hip-flexion-right",
    metricId: "right-hip-angle-yz",
    minMax: "max",
    yLabel: "Max hip angle from vertical",
    trendLineLabel: "",
  },
  {
    id: 11,
    dropdownName: "Hip abduction right",
    gestureType: "hip-abduction-right",
    metricId: "right-hip-ankle-angle-xy",
    minMax: "max",
    yLabel: "Max hip angle from vertical",
    trendLineLabel: "",
  },
  {
    id: 12,
    dropdownName: "Squat (right knee)",
    gestureType: "squat, squat-up",
    metricId: "right-knee-angle",
    minMax: "min",
    yLabel: "Min knee angle",
    trendLineLabel: "",
  },
  {
    id: 13,
    dropdownName: "Shoulder abduction right",
    gestureType: "shoulder-abduction-right",
    metricId: "right-shoulder-hand-angle-xy",
    minMax: "max",
    yLabel: "Range of motion",
    trendLineLabel: "",
  },
  {
    id: 14,
    dropdownName: "Shoulder flexion right",
    gestureType:
      "shoulder-flexion-elbow-extension-bimanual, shoulder-flexion-supination-right, shoulder-flexion-elbow-extension-right",
    metricId: "right-shoulder-hand-angle-yz",
    minMax: "max",
    yLabel: "Range of motion",
    trendLineLabel: "",
  },
  {
    id: 15,
    dropdownName: "Lateral trunk flexion left",
    gestureType: "lean-right",
    metricId: "torso-angle-xy",
    minMax: "max",
    yLabel: "Range of motion",
    trendLineLabel: "Max lateral trunk angle",
  },
  {
    id: 16,
    dropdownName: "Lateral trunk flexion right",
    gestureType: "lean-left",
    metricId: "torso-angle-xy",
    minMax: "max",
    yLabel: "Range of motion",
    trendLineLabel: "Max lateral trunk angle",
  },
  {
    id: 17,
    dropdownName: "Squat lean left (lateral trunk flexion)",
    gestureType: "squat-lean-left",
    metricId: "torso-angle-xy",
    minMax: "max",
    yLabel: "Trunk range of motion",
    trendLineLabel: "Max lateral trunk angle",
  },
  {
    id: 18,
    dropdownName: "Squat lean right (lateral trunk flexion)",
    gestureType: "squat-lean-right",
    metricId: "torso-angle-xy",
    minMax: "max",
    yLabel: "Trunk range of motion",
    trendLineLabel: "Max lateral trunk angle",
  },
  {
    id: 19,
    dropdownName: "Squat lean left (knee angle)",
    gestureType: "squat-lean-left",
    metricId: "left-knee-angle-yz",
    minMax: "min",
    yLabel: "Min knee angle",
    trendLineLabel: "",
  },
  {
    id: 20,
    dropdownName: "Squat lean right (knee angle)",
    gestureType: "squat-lean-right",
    metricId: "right-knee-angle-yz",
    minMax: "min",
    yLabel: "Min knee angle",
    trendLineLabel: "",
  },
  {
    id: 21,
    dropdownName: "Romanian dead lift, left stance twist right",
    gestureType: "warrior3-twist-right-Lstance",
    metricId: "trunk-rot-angle",
    minMax: "max",
    yLabel: "Max trunk rotation (degrees)",
    trendLineLabel: "",
  },
  {
    id: 22,
    dropdownName: "Romanian dead lift, right stance twist right",
    gestureType: "warrior3-twist-right-Rstance",
    metricId: "trunk-rot-angle",
    minMax: "max",
    yLabel: "Max trunk rotation (degrees)",
    trendLineLabel: "",
  },
  {
    id: 23,
    dropdownName: "Romanian dead lift, left stance twist left",
    gestureType: "warrior3-twist-left-Lstance",
    metricId: "trunk-rot-angle",
    minMax: "max",
    yLabel: "Max trunk rotation (degrees)",
    trendLineLabel: "",
  },
  {
    id: 24,
    dropdownName: "Romanian dead lift, right stance twist left",
    gestureType: "warrior3-twist-left-Rstance",
    metricId: "trunk-rot-angle",
    minMax: "max",
    yLabel: "Max trunk rotation (degrees)",
    trendLineLabel: "",
  },
  {
    id: 25,
    dropdownName: "Trunk rotation left",
    gestureType: "trunk-rotation-left",
    metricId: "trunk-rotation-angle-xz",
    minMax: "max",
    yLabel: "Max trunk rotation (degrees)",
    trendLineLabel: "",
  },
  {
    id: 26,
    dropdownName: "Trunk rotation right",
    gestureType: "trunk-rotation-right",
    metricId: "trunk-rotation-angle-xz",
    minMax: "max",
    yLabel: "Max trunk rotation (degrees)",
    trendLineLabel: "",
  },
  {
    id: 27,
    dropdownName: "Trunk extension",
    gestureType:
      "lean-backwards0, lean-backwards5, lean-backwards10, lean-backwards15, lean-backwards20",
    metricId: "trunk-angle-yz",
    minMax: "delta",
    yLabel: "Range of motion (max - min degrees)",
    trendLineLabel: "",
  },
  {
    id: 28,
    dropdownName: "Romanian dead lift, right stance (trunk flexion)",
    gestureType:
      "warrior3-twist-left-Rstance, warrior3-twist-right-Rstance, warrior3-Rstance",
    metricId: "torso-angle-yz",
    minMax: "max",
    yLabel: "Max trunk flexion",
    trendLineLabel: "",
  },
  {
    id: 29,
    dropdownName: "Romanian dead lift, left stance (trunk flexion)",
    gestureType:
      "warrior3-twist-left-Lstance, warrior3-twist-right-Lstance, warrior3-Lstance",
    metricId: "torso-angle-yz",
    minMax: "max",
    yLabel: "Max trunk flexion",
    trendLineLabel: "",
  },
  {
    id: 30,
    dropdownName: "Trunk flexion",
    gestureType: "lean-forward",
    metricId: "torso-angle-yz",
    minMax: "delta",
    yLabel: "Range of motion (max - min degrees)",
    trendLineLabel: "",
  },
  {
    id: 31,
    dropdownName: "Head tilt left",
    gestureType: "head-tilt-left",
    metricId: "neck-angle-xy",
    minMax: "max",
    yLabel: "Max head tilt angle",
    trendLineLabel: "",
  },
  {
    id: 32,
    dropdownName: "Clamshell (angle)",
    gestureType: "clam-shell-open",
    metricId: "right-hip-ankle-angle-xy",
    minMax: "max",
    yLabel: "Max hip-ankle angle",
    trendLineLabel: "",
  },
  {
    id: 33,
    dropdownName: "Lunge left knee angle",
    gestureType: "lunge-backward-left",
    metricId: "left-knee-angle",
    minMax: "min",
    yLabel: "Min knee angle",
    trendLineLabel: "",
  },
  {
    id: 34,
    dropdownName: "Lunge right knee angle",
    gestureType: "lunge-backward-right",
    metricId: "right-knee-angle",
    minMax: "min",
    yLabel: "Min knee angle",
    trendLineLabel: "",
  },
  {
    id: 35,
    dropdownName: "Lunge right hip-to-foot angle",
    gestureType: "lunge-side-left",
    metricId: "right-hip-ankle-angle-xy",
    minMax: "max",
    yLabel: "Max hip angle",
    trendLineLabel: "",
  },
  {
    id: 36,
    dropdownName: "Lunge left hip-to-foot angle",
    gestureType: "lunge-side-right",
    metricId: "left-hip-ankle-angle-xy",
    minMax: "max",
    yLabel: "Max hip angle",
    trendLineLabel: "",
  },
  {
    id: 37,
    dropdownName: "Knee extension right",
    gestureType: "right-knee-extension",
    metricId: "right-knee-angle",
    minMax: "max",
    yLabel: "Max knee extension",
    trendLineLabel: "",
  },
  {
    id: 38,
    dropdownName: "Knee extension left",
    gestureType: "left-knee-extension",
    metricId: "left-knee-angle",
    minMax: "max",
    yLabel: "Max knee extension",
    trendLineLabel: "",
  },
  {
    id: 39,
    dropdownName: "Shoulder adduction right",
    gestureType: "shoulder-adduction-right",
    metricId: "right-hip-center-hand-vector-x",
    minMax: "max",
    yLabel: "Max crossbody reach (cm)",
    trendLineLabel: "",
  },
  {
    id: 40,
    dropdownName: "Shoulder adduction left",
    gestureType: "shoulder-adduction-left",
    metricId: "left-hand-hip-center-vector-x",
    minMax: "max",
    yLabel: "Max crossbody reach (cm)",
    trendLineLabel: "",
  },
  {
    id: 41,
    dropdownName: "Overhead reach left",
    gestureType: "swat-up-left, overhead-reach-left",
    metricId: "left-head-hand-vector-y",
    minMax: "max",
    yLabel: "Max overhead reach (cm)",
    trendLineLabel: "",
  },
  {
    id: 42,
    dropdownName: "Overhead reach right",
    gestureType: "swat-up-right, overhead-reach-right",
    metricId: "right-head-hand-vector-y",
    minMax: "max",
    yLabel: "Max overhead reach (cm)",
    trendLineLabel: "",
  },
  {
    id: 43,
    dropdownName: "Romanian dead lift, left stance twist left (ankle distance)",
    gestureType: "warrior3-twist-left-Lstance",
    metricId: "left-ankle-right-ankle-vector-y",
    minMax: "max",
    yLabel: "Max ankle-to-ankle vertical distance (cm)",
    trendLineLabel: "",
  },
  {
    id: 44,
    dropdownName: "Romanian dead lift, right stance twist left (ankle distance)",
    gestureType: "warrior3-twist-left-Rstance",
    metricId: "right-ankle-left-ankle-vector-y",
    minMax: "max",
    yLabel: "Max ankle-to-ankle vertical distance (cm)",
    trendLineLabel: "",
  },
  {
    id: 45,
    dropdownName: "Romanian dead lift, right stance twist right (ankle distance)",
    gestureType: "warrior3-twist-right-Rstance",
    metricId: "right-ankle-left-ankle-vector-y",
    minMax: "max",
    yLabel: "Max ankle-to-ankle vertical distance (cm)",
    trendLineLabel: "",
  },
  {
    id: 46,
    dropdownName: "Romanian dead lift, left stance twist right (ankle distance)",
    gestureType: "warrior3-twist-right-Lstance",
    metricId: "left-ankle-right-ankle-vector-y",
    minMax: "max",
    yLabel: "Max ankle-to-ankle vertical distance (cm)",
    trendLineLabel: "",
  },
  {
    id: 47,
    dropdownName: "Knee raise left",
    gestureType: "knee-raise-left",
    metricId: "right-ankle-left-ankle-vector-y",
    minMax: "max",
    yLabel: "Max ankle-to-ankle vertical distance (cm)",
    trendLineLabel: "",
  },
  {
    id: 48,
    dropdownName: "Knee raise right",
    gestureType: "knee-raise-right",
    metricId: "left-ankle-right-ankle-vector-y",
    minMax: "max",
    yLabel: "Max ankle-to-ankle vertical distance (cm)",
    trendLineLabel: "",
  },
  {
    id: 49,
    dropdownName: "Knee raise left (seated)",
    gestureType: "knee-raise-seated-left",
    metricId: "right-ankle-left-ankle-vector-y",
    minMax: "max",
    yLabel: "Max ankle-to-ankle vertical distance (cm)",
    trendLineLabel: "",
  },
  {
    id: 50,
    dropdownName: "Knee raise right (seated)",
    gestureType: "knee-raise-seated-right",
    // metricId: "left-ankle-right-ankle-vector-y",
    metricId: "right-ankle-left-ankle-vector-y",
    minMax: "max",
    yLabel: "Max ankle-to-ankle vertical distance (cm)",
    trendLineLabel: "",
  },
  {
    id: 51,
    dropdownName: "Shoulder shrug right",
    gestureType: "shoulder-shrug-right",
    metricId: "rightshoulder-leftshoulder-vector-y",
    minMax: "min",
    yLabel: "Max shoulder-to-shoulder y",
    trendLineLabel: "",
  },
  {
    id: 52,
    dropdownName: "Shoulder shrug left",
    gestureType: "shoulder-shrug-left",
    metricId: "rightshoulder-leftshoulder-vector-y",
    minMax: "max",
    yLabel: "Max shoulder-to-shoulder y",
    trendLineLabel: "",
  },
  {
    id: 53,
    dropdownName: "Raise feet (right)",
    gestureType: "raise-feet",
    metricId: "right-hip-knee-vector-y",
    minMax: "max",
    yLabel: "Max knee over hip (cm)",
    trendLineLabel: "",
  },
  {
    id: 54,
    dropdownName: "Raise feet (left)",
    gestureType: "raise-feet",
    metricId: "left-hip-knee-vector-y",
    minMax: "max",
    yLabel: "Max knee over hip (cm)",
    trendLineLabel: "",
  },
  // {
  //   id: 55,
  //   dropdownName: "Clamshell (width)",
  //   gestureType: "clam-shell-open",
  //   metricId: "right-knee-left-knee-vector-x",
  //   minMax: "max",
  //   yLabel: "Max between knee distance (cm)",
  //   trendLineLabel: "",
  // },
  {
    id: 56,
    dropdownName: "Spinal balance right",
    gestureType: "spinal-balance-right",
    metricId: "left-hand-right-hand-vector-y",
    minMax: "max",
    yLabel: "Max right arm raise (cm)",
    trendLineLabel: "",
  },
  {
    id: 57,
    dropdownName: "Spinal balance left",
    gestureType: "spinal-balance-left",
    metricId: "right-hand-left-hand-vector-y",
    minMax: "max",
    yLabel: "Max left arm raise (cm)",
    trendLineLabel: "",
  },
  {
    id: 58,
    dropdownName: "Touch toes facing left",
    gestureType: 'trunk-flexion-standing',
    metricId: 'bend-at-waist-angle',
    minMax: 'max',
    yLabel: 'Max trunk angle from vertical',
    trendLineLabel: "",
  },
  {
    id: 59,
    dropdownName: "Touch toes seated facing left",
    gestureType: 'trunk-flexion-seated',
    metricId: 'trunk-angle',
    minMax: 'min',
    yLabel: 'Min trunk angle from horizontal',
    trendLineLabel: "",
  },
  {
    id: 60,
    dropdownName: "Weight shift left",
    gestureType: 'knee-bend-left',
    metricId: 'left-knee-angle-yz',
    minMax: 'min',
    yLabel: 'Min knee angle',
    trendLineLabel: "",
  },
  {
    id: 61,
    dropdownName: "Weight shift right",
    gestureType: 'knee-bend-right',
    metricId: 'right-knee-angle-yz',
    minMax: 'min',
    yLabel: 'Min knee angle',
    trendLineLabel: "",
  },
  {
    id: 62,
    dropdownName: "Head tilt right",
    gestureType: "head-tilt-right",
    metricId: "neck-angle-xy",
    minMax: "max",
    yLabel: "Max head tilt angle",
    trendLineLabel: "",
  },
  {
    id: 63,
    dropdownName: "Knee raise left facing right",
    gestureType: "knee-raise-heroes-left",
    metricId: "left-knee-hip-angle-xy",
    minMax: "max",
    yLabel: "Max knee-hip angle from vertical",
    trendLineLabel: "",
  },
  {
    id: 64,
    dropdownName: "Knee raise right facing left",
    gestureType: "knee-raise-heroes-right",
    metricId: "right-knee-hip-angle-xy",
    minMax: "max",
    yLabel: "Max knee-hip angle from vertical",
    trendLineLabel: "",
  },
];
