import * as React from "react";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import { Box, Button, Container, Flex } from "@chakra-ui/react";
import { Navbar } from "./components/Navbar";
import { Footer } from "../../components/Footer";
import { useQuery } from "react-query";
import { clearToken, hasToken } from "../../lib/store";
import { getUser } from "../../api";
import { UserType } from "../../types/enum";
import { PatientBlocker } from "../../components/PatientBlocker";

function Dashboard() {
  const { data } = useQuery<User, Error>("user", getUser);

  if (data && data.roles && data.roles.length == 1 && data.roles.includes(UserType.PATIENT)) {
    return (
      <PatientBlocker user={data} />
    )
  }

  return (
    <Box className="Home" as="section" bg={{ base: "gray.50" }} minH="100vh">
      <Navbar user={data} />
      <Container
        maxW="6xl"
        pt={{ base: 8, lg: 12 }}
        pb={{ base: 12, lg: 24 }}
        px={{ base: 4, md: 8, xl: 0 }}
      >
        <Outlet />
      </Container>
      <Footer />
    </Box>
  );
}

export default Dashboard;
