import * as React from "react";
import {
  Flex,
  Heading,
  Stack,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
  Grid,
} from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { getPatientById } from "../../../../api";
import { Stat } from "./components/Stat";
import { PatientChart } from "./components/PatientChart";

const PatientDetail = () => {
  const navigate = useNavigate();
  const { id = "" } = useParams<{ id: string }>();

  const {
    data: patient,
    isLoading,
    // isSuccess,
    // error,
  } = useQuery<Patient | null>("patient", () => {
    return getPatientById(id);
  });

  return (
    <Stack spacing={{ base: "8", lg: "6" }}>
      <Stack
        spacing="4"
        direction={{ base: "column", lg: "row" }}
        alignItems="flex-end"
        justify="space-between"
      >
        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbLink href="/">All Patients</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink href={`/clients/${patient?.id}`}>
              {patient?.username}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Text>Analytics</Text>
          </BreadcrumbItem>
        </Breadcrumb>
      </Stack>
      <Stack flex={1} pt={6} gap={8}>
        <Flex gap={4} pb={4}>
          <Heading size="lg" flex={1}>
            Analytics
          </Heading>
        </Flex>
      </Stack>
      <Heading size="md">Activity Overview</Heading>
      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(3, 1fr)",
        }}
        gap={6}
        pb={12}
      >
        <Stat label="Total activity" extra="Last 7 days" hourOffset={168} />
        <Stat label="Total activity" extra="Last 30 days" hourOffset={720} />
        <Stat label="Total activity" extra="last year" hourOffset={8760} />
      </Grid>
      <Heading size="md">View by Exercise</Heading>
      <PatientChart />
    </Stack>
  );
};

export default PatientDetail;
