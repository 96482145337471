import {
  Container,
  Stack,
  Heading,
  Text,
  useBreakpointValue,
  Button,
  Card,
  Icon,
  Box,
} from "@chakra-ui/react";
import { Link as ReactLink } from "react-router-dom";
import React from "react";
import { Footer } from "../../components/Footer";
import { Logo } from "../../components/Logo";
import { FiAlertCircle } from "react-icons/fi";

const NotFound = () => {
  return (
    <Box className="Home" as="section" bg={{ base: "gray.50" }} minH="100vh">
      <Container maxW="md" py={{ base: "12", md: "24" }}>
        <Stack spacing="8">
          <Stack spacing="6">
            <a href="https://rxgames.com">
              <Logo justifyContent="center" />
            </a>
            <Stack
              textAlign="center"
              minH={useBreakpointValue({ base: 200 })}
              mt={useBreakpointValue({ md: 12 })}
              borderRadius="lg"
              bgColor="white"
              py={12}
              px={6}
            >
              <Heading as="h1">
                <Icon as={FiAlertCircle} color="red.500" />
                <br />
                Page Not Found
              </Heading>
              <Text pb={useBreakpointValue({ base: 6, md: 8 })}>
                We looked and couldn&apos;t find that page. If you need help,
                please reach out to support.
              </Text>
              <Button as={ReactLink} to="/login" alignSelf="center">
                Back to Login
              </Button>
            </Stack>
          </Stack>
        </Stack>
        <Footer />
      </Container>
    </Box>
  );
};

export default NotFound;
