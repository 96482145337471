import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  Text,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import * as React from "react";
import { useMutation } from "react-query";
import { addOrganization, downloadCSV, editOrganization } from "../../../../api";

interface EditOrganizationPopupProps {
  onOrganizationEdited?: (succeeded: boolean) => void;
  Organization: Organization;
}

export const EditOrganizationPopup = ({ Organization, onOrganizationEdited }: EditOrganizationPopupProps) => {
  const isOpen = true;
  const [name, setName] = React.useState(`${Organization.name}`);
  const [error, setError] = React.useState<string | null>(null);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  console.log('Um hello? ', Organization, name);

  const onClose = (succeeded = false) => onOrganizationEdited && onOrganizationEdited(succeeded);

  const initialRef = React.useRef(null);

  const handleSave = async (pk: string, name: string) => {
    setLoading(true);

    try {
      let response = await editOrganization({ pk, name, });
      setLoading(false);
      onClose(true);
    } catch (error) {
      console.error("Error saving prescription: ", error);
      setError("Sorry, Organization could not be edited at this time.");
      setLoading(false);
      return;
    }
  };

  const renderError = () => {
    if (error) {
      return (
        <Text color="red" mt={4}>
          {error}
        </Text>
      );
    }
    return null;
  };

  const handleSaveClick = async () => {
    setError(null);

    console.log('UM: ', Organization);
    handleSave(Organization.pk, name);
  };

  return (
    <>
      <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Organization</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                ref={initialRef}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>

            {renderError()}
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={handleSaveClick}
              isLoading={isLoading}
            >
              Save
            </Button>
            <Button onClick={() => onClose()}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
